import { Typography, Paper } from "@mui/material";
import { AbilityContext } from "./Authorisation/AbilityContext";
import { useAbility } from "@casl/react";
import DefinitionsList from "./Definitions/DefinitionsList";
import RuntimeWrapper from "./RuntimeWrapper/RuntimeWrapper";
import Streams from "./EventDefinitions/EventDefinitions";
import EventLog from "./EventLog/EventLog";
import WorkflowLogs from "./WorkflowLogs/WorkflowLogs";
import Repositories from "./Repositories/Repositories";
import ErrorIcon from "@mui/icons-material/Error";
import { classes } from "../App.Styles";

const Home = (): JSX.Element => {
  const ability = useAbility(AbilityContext);

  if (ability.can("view", "workflow")) {
    return <DefinitionsList />;
  } else if (ability.can("view", "eventDefinition")) {
    return <Streams />;
  } else if (ability.can("view", "task")) {
    return <RuntimeWrapper />;
  } else if (ability.can("view", "event")) {
    return <EventLog />;
  } else if (ability.can("view", "workflowLog")) {
    return <WorkflowLogs />;
  } else if (ability.can("view", "repository")) {
    return <Repositories />;
  } else {
    return (
      <Paper
        elevation={4}
        className={classes.table}
        style={{ padding: 20, display: "flex" }}
      >
        <ErrorIcon
          color="secondary"
          style={{
            marginTop: "5px",
            marginRight: "10px",
            verticalAlign: "sub",
          }}
        />
        <Typography variant="h6" aria-label="No Roles">
          You do not have any roles assigned to access AireFlow functionality.
          Please contact your system administrator to gain any required access.
        </Typography>
      </Paper>
    );
  }
};

export default Home;
