import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { JsonEditor } from "../../Editors";
import classNames from "classnames";
import CopyToClipboard from "react-copy-to-clipboard";
import Loading from "../../Loading/Loading";
import SnackbarAlert from "../../SnackbarAlert";
import { classes } from "../../../App.Styles";

interface IProps {
  definitionName: string;
  definitionJson: undefined | string;
  error: string | undefined;
  loading: boolean;
  onErrorClose: () => void;
}

const DefinitionExport: React.FC<IProps> = (props: IProps) => {
  const { definitionName, definitionJson, error, loading, onErrorClose } =
    props;

  const [showSnackbar, setShowSnackbar] = useState(false);

  return (
    <div className={classes.container}>
      <Loading visible={loading} />
      <SnackbarAlert
        open={!!error}
        onClose={onErrorClose}
        colour="error"
        message={error ?? ""}
      />
      <SnackbarAlert
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        colour="success"
        message={`Copied ${definitionName} to Clipboard`}
      />
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5">
            Export <code>{definitionName}</code>
          </Typography>
        </Grid>
        {!!definitionJson && (
          <>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <CopyToClipboard
                text={definitionJson}
                onCopy={() => setShowSnackbar(true)}
              >
                <Button
                  className={classNames(
                    classes.button,
                    classes.buttonCancel,
                    classes.noMargin,
                  )}
                >
                  Copy to Clipboard
                </Button>
              </CopyToClipboard>
            </Grid>
            <Grid item xs={12}>
              <JsonEditor readOnly value={definitionJson} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default DefinitionExport;
