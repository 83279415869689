import React, { useEffect, useState } from "react";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableToolbar from "./TableToolbar";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  Column,
  useExpanded,
  useFlexLayout,
} from "react-table";
import { Box, Button, Table, Typography } from "@mui/material";
import { TableAddRow } from "./TableAddRow";
import IDataTableProps from "./IDataTableProps";
export type { Column };

export function DataTable<T>({
  columns,
  data,
  total,
  skipPageReset,
  initialState,
  renderDetail,
  name,
  addProps,
  pagination,
  setPagination,
  importProps,
  renderImportModal,
  emptyDataSourceMessage,
  filterDisabled,
  footerAction,
  footerActionLabel,
  customProps,
  renderCustomModal,
}: IDataTableProps<T>): JSX.Element {
  const getRowId = React.useCallback((row: any) => {
    return row.id;
  }, []);

  const {
    getTableProps,
    headerGroups,
    headers,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    rows,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable<any>(
    {
      columns,
      data,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      initialState,
      name,
      autoResetExpanded: false,
      getRowId,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
  );

  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const handleSetGlobalFilter = (value?: string | undefined) => {
    setIsFiltered(!!value);
    setGlobalFilter(value);
    if (pagination && setPagination) {
      // set the filter and set the page back to 1
      setPagination({
        ...pagination,
        page: 1,
        filter: value,
      });
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    if (pagination && setPagination) {
      setPagination({
        ...pagination,
        page: newPage + 1,
      });
    } else {
      gotoPage(newPage);
    }
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    if (pagination && setPagination) {
      setPagination({
        ...pagination,
        page: 1,
        pageSize: parseInt(event.target.value),
      });
    }
    setPageSize(Number(event.target.value));
  };

  const [addRowDisplay, setAddRowDisplay] = useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [showCustomModal, setShowCustomModal] = useState<boolean>(false);

  useEffect(() => {
    //We need to have an id on each of the columns if the data-table will be using the add functionality. React-Table has Id as optional which is why this check is here.
    if (addProps != null && columns.some((c) => !c.id)) {
      throw Error(
        "Error: When you are using the add functionality in the DataTable, please provide all columns with an Id.",
      );
    }
  }, [columns]);

  useEffect(() => {
    if (addProps?.disabled === true) {
      setAddRowDisplay(false);
    }
  }, [addProps?.disabled]);

  return (
    <>
      <TableContainer style={{ overflowX: "unset" }}>
        <TableToolbar
          title={name}
          setGlobalFilter={handleSetGlobalFilter}
          globalFilter={globalFilter}
          addProps={{
            allowed: addProps?.allowed,
            onSelect: () => setAddRowDisplay(true),
            disabled: addRowDisplay || (addProps?.disabled ?? false),
            identifier: addProps?.identifier,
          }}
          importProps={{
            allowed: importProps?.allowed,
            onSelect: () => setShowImportModal(true),
            disabled: showImportModal || (importProps?.disabled ?? false),
            identifier: importProps?.identifier,
          }}
          customProps={
            customProps
              ? {
                  onSelect: () => setShowCustomModal(true),
                  identifier: customProps.identifier,
                  icon: customProps.icon,
                  label: customProps.label,
                }
              : undefined
          }
          filterDisabled={filterDisabled}
        />
        <div style={{ overflow: "auto" }}>
          <MaUTable {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup, i) => (
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  key={"hg_row_" + i}
                >
                  {headerGroup.headers.map((column, j) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={`hg_row_${i}_cell_${j}`}
                    >
                      {column.render("Header")}
                      {column.isSorted && (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableAddRow
              display={addRowDisplay}
              fields={addProps?.rowFields ?? []}
              headerGroups={headerGroups}
              headers={headers}
              onSave={addProps?.onSave}
              onCancel={addProps?.onCancel}
              setAddRowDisplay={setAddRowDisplay}
              validate={addProps?.validate}
              renderAddDetail={addProps?.renderDetail}
              isExpanded={addProps?.expanded}
            ></TableAddRow>
            <TableBody>
              {page.length == 0 ? (
                <TableRow>
                  <TableCell>
                    <Typography
                      paragraph={false}
                      align="center"
                      style={{ margin: 100 }}
                    >
                      {emptyDataSourceMessage}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={`${row.original.id}-fragment`}>
                      <TableRow
                        {...row.getRowProps()}
                        key={`${row.original.id}-table-row`}
                      >
                        {row.cells.map((cell, cellI) => {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              key={"p_cell_" + cellI}
                              id={`${cell.column.id}_cell_${i}`}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                height="100%"
                              >
                                {cell.render("Cell")}
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      {row.isExpanded && !!renderDetail ? (
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={999}>
                            {renderDetail(
                              row.original,
                              row.getToggleRowExpandedProps,
                            )}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </React.Fragment>
                  );
                })
              )}
            </TableBody>
          </MaUTable>
        </div>
        {total > 0 && (
          <Table>
            <TableFooter>
              <TableRow>
                {footerAction && footerActionLabel && (
                  <TableCell sx={{ p: 0 }}>
                    <Box p={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={footerAction}
                      >
                        {footerActionLabel}
                      </Button>
                    </Box>
                  </TableCell>
                )}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  count={
                    !isFiltered || (pagination && setPagination)
                      ? total
                      : rows.length
                  }
                  colSpan={7}
                  rowsPerPage={pagination ? pagination.pageSize : pageSize}
                  page={pagination ? pagination.page - 1 : pageIndex}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {importProps && !!renderImportModal
        ? renderImportModal(showImportModal, () => setShowImportModal(false))
        : null}
      {showCustomModal && !!renderCustomModal
        ? renderCustomModal(() => setShowCustomModal(false))
        : null}
    </>
  );
}
