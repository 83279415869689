import { IDueDateProperties } from "../Interfaces/IDueDateProperties";
import { formatDate, isValidDateTime } from "./DateHelper";

class ToUIResponse {
  DueDateUI: IDueDateProperties;
  Parsed: boolean;

  constructor(dueDateUI: IDueDateProperties, parsed: boolean) {
    this.DueDateUI = dueDateUI;
    this.Parsed = parsed;
  }
}

export default class DueDateParser {
  public static ToCode(dueDateUI: IDueDateProperties): string {
    const plusTimeParts: string[] = [];
    const roundToNextParts: string[] = [];

    if (dueDateUI.Months > 0) {
      plusTimeParts.push(`months: ${dueDateUI.Months}`);
    }

    if (dueDateUI.Weeks > 0) {
      plusTimeParts.push(`weeks: ${dueDateUI.Weeks}`);
    }

    if (dueDateUI.Days > 0) {
      plusTimeParts.push(`days: ${dueDateUI.Days}`);
    }

    if (dueDateUI.Hours > 0) {
      plusTimeParts.push(`hours: ${dueDateUI.Hours}`);
    }

    if (dueDateUI.Minutes > 0) {
      plusTimeParts.push(`minutes: ${dueDateUI.Minutes}`);
    }

    if (dueDateUI.RoundToNext.length > 0) {
      dueDateUI.RoundToNext.forEach((v: string) => {
        roundToNextParts.push(`round_to_next: '${v}'`);
      });
    }

    let expressionString = `"{{ '${
      dueDateUI.IsNow ? "now" : formatDate(dueDateUI.DateInput)
    }'`;

    const plusTimeArguments = plusTimeParts.join(", ");

    if (plusTimeParts.length > 0) {
      expressionString += ` | plus_time: ${plusTimeArguments}`;
    }

    if (roundToNextParts.length > 0) {
      roundToNextParts.forEach((f: string) => {
        expressionString += ` | ${f}`;
      });
    }

    expressionString += ` }}"`;
    return expressionString;
  }

  public static ToUI(dueDateExpression: string): ToUIResponse {
    const dueDateUI: IDueDateProperties = {
      Months: 0,
      Weeks: 0,
      Days: 0,
      Hours: 0,
      Minutes: 0,
      RoundToNext: [],
      IsNow: false,
      DateInput: "",
    };

    let foundMatch = false; // Track if any property has been matched
    if (!dueDateExpression) return new ToUIResponse(dueDateUI, foundMatch);

    dueDateUI.IsNow =
      dueDateExpression.includes("now") || dueDateExpression.includes("today");

    if (!dueDateUI.IsNow) {
      const inputDateRegex = /^"{{\s*(['"])(.*?)\1\s*.*?\|/;
      const match = dueDateExpression.match(inputDateRegex);
      const capturedValue = match ? match[2].trim() : "";
      if (isValidDateTime(capturedValue))
        dueDateUI.DateInput = formatDate(capturedValue);
    }

    if (dueDateExpression.includes("plus_time:")) {
      const plusFilterRegex =
        /months: (\d+)|weeks: (\d+)|days: (\d+)|hours: (\d+)|minutes: (\d+)/g;
      let match;

      while ((match = plusFilterRegex.exec(dueDateExpression)) !== null) {
        const [, months, weeks, days, hours, minutes] = match;

        if (months !== undefined) {
          dueDateUI.Months = parseInt(months);
          foundMatch = true;
        } else if (weeks !== undefined) {
          dueDateUI.Weeks = parseInt(weeks);
          foundMatch = true;
        } else if (days !== undefined) {
          dueDateUI.Days = parseInt(days);
          foundMatch = true;
        } else if (hours !== undefined) {
          dueDateUI.Hours = parseInt(hours);
          foundMatch = true;
        } else if (minutes !== undefined) {
          dueDateUI.Minutes = parseInt(minutes);
          foundMatch = true;
        }
      }
    }

    if (dueDateExpression.includes("round_to_next:")) {
      const roundToNextFilterRegex =
        /round_to_next: '(month|hour|weekday|monday|tuesday|wednesday|thursday|friday|saturday|sunday)'/gi;
      const matches = Array.from(
        dueDateExpression.matchAll(roundToNextFilterRegex),
      );
      const values = matches.map((match) => match[1]);

      values.forEach((element) => {
        dueDateUI.RoundToNext.push(element.toLowerCase().trim());
        foundMatch = true;
      });
    }

    return new ToUIResponse(dueDateUI, foundMatch);
  }
}
