import React, { useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Chip,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import classNames from "classnames";
import { ITableEventDefinitionGroup } from "../../Common/Interfaces/ITableEventDefinitionGroup";
import { useState } from "react";
import IEventDefinition from "../../Common/Interfaces/IEventDefinition";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  eventDefinitionGroup: ITableEventDefinitionGroup;
  eventDefinitions: IEventDefinition[];
  onSave: (eventDefinitionGroup: ITableEventDefinitionGroup) => void;
  onCancel: (eventDefinitionGroupId: string) => void;
}

const EventDefinitionGroupDetail: React.FC<IProps> = ({
  eventDefinitionGroup,
  eventDefinitions,
  onSave,
  onCancel,
  readOnly,
}) => {
  const [selectedEventKeys, setSelectedEventKeys] = useState<string[]>(
    eventDefinitionGroup.eventKeys,
  );

  const [eventMap, setEventMap] = useState<{
    [eventKey: string]: IEventDefinition;
  }>({});
  useEffect(() => {
    const newEventMap: { [eventKey: string]: IEventDefinition } = {};
    eventDefinitions.forEach((eventDefinition) => {
      newEventMap[eventDefinition.eventKey] = eventDefinition;
    });
    setEventMap(newEventMap);
  }, [eventDefinitions]);

  const findEvent = (eventKey: string): IEventDefinition =>
    eventMap[eventKey] ?? { name: "", eventKey: eventKey };

  const onSaveAction = () => {
    onSave({
      ...eventDefinitionGroup,
      eventKeys: selectedEventKeys,
    });
  };

  const onCancelAction = () => {
    setSelectedEventKeys(eventDefinitionGroup.eventKeys);
    onCancel(eventDefinitionGroup.id);
  };

  const handleChange = (_event: any, value: string[]) => {
    setSelectedEventKeys(value);
  };

  const eventComponent = (event: IEventDefinition, props?: any) => {
    return event.name ? (
      <div {...props} key={event.eventKey}>
        <span style={{ marginRight: "0.3em" }}>{event.name}</span>
        <span
          style={{ fontFamily: "monospace" }}
          className={classes.mediumGrey}
        >
          {" "}
          ({event.eventKey})
        </span>
      </div>
    ) : (
      <div {...props} key={event.eventKey}>
        <span style={{ fontFamily: "monospace" }}>{event.eventKey}</span>
      </div>
    );
  };

  return (
    <div className={classes.container} id="event_definition_group_detail">
      <Grid container>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          className={classNames(classes.itemContainer, classes.extraPadding)}
        >
          <Grid item xs={12}>
            <Typography variant="h5">
              Edit {eventDefinitionGroup.name}{" "}
              <span className={classes.mediumGrey}>
                ({eventDefinitionGroup.groupKey})
              </span>
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Select event definitions or type an event key and press enter to
              add events to this group.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="event-multi-select"
              multiple
              options={eventDefinitions.map((def) => def.eventKey)}
              freeSolo
              disableCloseOnSelect
              filterSelectedOptions
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const event = findEvent(option);
                  return (
                    <Tooltip
                      key={`${event.eventKey}-tooltip`}
                      title={event.name ? "Event Definition" : "Event Key"}
                    >
                      <Chip
                        variant="outlined"
                        label={eventComponent(event)}
                        {...getTagProps({ index })}
                        key={`${event.eventKey}-chip`}
                      />
                    </Tooltip>
                  );
                })
              }
              value={selectedEventKeys}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Events" />
              )}
              renderOption={(props, option) =>
                eventComponent(findEvent(option), props)
              }
              onChange={handleChange}
              disabled={readOnly}
            />
          </Grid>
          {!readOnly && (
            <Grid
              item
              xs={12}
              className={classNames(
                classes.itemContainer,
                classes.buttonContainer,
                classes.transparentBorderless,
              )}
            >
              <Button
                className={classNames(classes.button, classes.buttonCancel)}
                onClick={onCancelAction}
              >
                Cancel
              </Button>
              <Button
                className={classNames(classes.button, classes.buttonSave)}
                onClick={onSaveAction}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default EventDefinitionGroupDetail;
