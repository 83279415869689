import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Background,
  BackgroundVariant,
  Controls,
  ReactFlowProvider,
  EdgeTypes,
} from "reactflow";
import "reactflow/dist/style.css";
import "./StyleOverride.css"; // Override some styles of React Flow
import { useRelationshipsService } from "../../Common/Hooks/useRelationshipsService";
import { getNodesAndEdges } from "./ChartBuilder";
import FloatingEdge from "./FloatingEdge";
import Loading from "../Loading/Loading";
import SnackbarAlert from "../SnackbarAlert";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../App.Styles";

const edgeTypes: EdgeTypes = {
  floating: FloatingEdge,
};

interface Props extends IReadOnlyComponentProps {
  id?: string;
  version: number;
}

const Relationships: React.FC<Props> = ({ id, version }) => {
  const service = useRelationshipsService();
  const [loading, setLoading] = useState<boolean>(false);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    let active = true;
    async function buildDiagram() {
      try {
        setLoading(true);
        const results = await service.$getById(id as string, version as number);
        const nodesAndEdges = getNodesAndEdges(results, id ?? "");
        if (active) {
          setNodes(nodesAndEdges.Nodes);
          setEdges(nodesAndEdges.Edges);
        }
      } catch {
        setError(`Error loading relationships`);
      } finally {
        setLoading(false);
      }
    }
    buildDiagram();
    return () => {
      active = false;
    };
  }, [version]);

  if (loading) return <Loading visible={true} />;

  return (
    <>
      <SnackbarAlert
        open={!!error}
        onClose={() => {
          setError(undefined);
        }}
        colour="error"
        message={error ?? ""}
      />
      {nodes.length > 0 ? (
        <div style={{ height: 600 }} data-qe-id="relationships_chart">
          <ReactFlowProvider>
            <ReactFlow
              defaultNodes={nodes}
              defaultEdges={edges}
              edgeTypes={edgeTypes}
              fitView
            >
              <Background variant={BackgroundVariant.Lines} gap={15} size={1} />
              <Controls />
            </ReactFlow>
          </ReactFlowProvider>
        </div>
      ) : (
        <Grid item container className={classes.errorContainer}>
          No relationships to display
        </Grid>
      )}
    </>
  );
};

export default Relationships;
