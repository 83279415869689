import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { classes } from "../../App.Styles";

interface IProps {
  name: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteConfirmation: React.FC<IProps> = ({
  name,
  onConfirm,
  onCancel,
}) => {
  return (
    <Grid container style={{ padding: "12px 20px" }}>
      <Grid item xs={9}>
        <Grid
          container
          direction="column"
          spacing={0}
          justifyContent="center"
          className={classes.fullHeight}
        >
          <Typography variant="h6">{`Delete ${name}?`}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container justifyContent="flex-end">
          <IconButton
            aria-label="Confirm"
            onFocus={(e) => e.stopPropagation()}
            onClick={(e) => {
              onConfirm();
              e.stopPropagation();
            }}
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            aria-label="Cancel"
            onFocus={(e) => e.stopPropagation()}
            onClick={(e) => {
              onCancel();
              e.stopPropagation();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeleteConfirmation;
