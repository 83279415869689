import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useState } from "react";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import { useStatusServicesContext } from "../Services/TaskStatusServiceContext";
import { classes } from "../App.Styles";
import { TaskType } from "../Common/Enums/TaskType";
import { TaskStatus } from "../Common/Enums/TaskStatus";
import { TaskStatusNameProvider } from "../Common/Providers/TaskStatusNameProvider";

interface IProps {
  taskStatus: TaskStatus;
  taskType: TaskType;
  onUpdate: (status: string) => Promise<boolean>;
}

const TaskStatusSelect: React.FC<IProps> = ({
  taskStatus,
  taskType,
  onUpdate,
}) => {
  const menuProps = {
    classes: {
      paper: classes.taskStatusList,
      list: classes.taskStatusListItem,
    },
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "right" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "right" as const,
    },
  };

  const { transitions } = useStatusServicesContext();

  const isTransitionAvailable = (): boolean => {
    if (taskType !== TaskType.Manual || !transitions?.has(originalStatus))
      return false;

    const toStates = transitions?.get(originalStatus);
    return toStates !== undefined && toStates.length > 0;
  };

  const iconComponent = (props: any) => {
    return isTransitionAvailable() ? (
      <ExpandIcon
        className={classNames(props.className, classes.taskStatusIcon)}
      />
    ) : (
      <span></span>
    );
  };

  const originalStatus = taskStatus;
  const [selected, setSelected] = useState(originalStatus);
  const [open, setOpen] = useState(false);

  const onChange = (event: SelectChangeEvent) => {
    event.stopPropagation();
    if (!event.target.value) return;
    const newStatus = event.target.value as TaskStatus;
    setSelected(newStatus);
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setSelected(originalStatus);
    setOpen(false);
  };

  const onSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const updateSuccessful = await onUpdate(selected);
    if (!updateSuccessful) {
      setSelected(originalStatus);
    }
    setOpen(!updateSuccessful);
  };

  return (
    <Select
      className={classNames(classes.taskStatus, classes.taskStatusColumnItem)}
      value={selected}
      IconComponent={iconComponent}
      onChange={onChange}
      onOpen={() => setOpen(true)}
      onClose={(event: any) => {
        if (event.target.value == undefined) {
          setOpen(false);
          setSelected(originalStatus);
        }
      }}
      open={isTransitionAvailable() && open}
      MenuProps={menuProps}
      variant="standard"
      disableUnderline={true}
    >
      <MenuItem value={originalStatus} key={originalStatus}>
        {TaskStatusNameProvider(originalStatus)}
      </MenuItem>
      {transitions?.get(originalStatus)?.map((s) => (
        <MenuItem value={s} key={s}>
          {TaskStatusNameProvider(s)}
        </MenuItem>
      ))}
      <div className={classes.taskStatusButtonContainer}>
        <Button
          className={classNames(classes.button, classes.buttonCancel)}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={classNames(classes.button, classes.buttonSave)}
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </Select>
  );
};

export default TaskStatusSelect;
