import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { classes } from "../../App.Styles";

interface IProps {
  label: string;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const TaskPropertyItem: React.FC<IProps> = ({
  label,
  value,
  onChange,
  disabled,
}) => {
  return (
    <Grid container key={label}>
      <Grid
        item
        container
        justifyContent="flex-end"
        alignItems="center"
        xs={3}
        md={4}
        xl={5}
      >
        <Typography className={classes.taskPropertyLabel}>{label}</Typography>
      </Grid>
      <Grid item container xs={9} md={8} xl={7}>
        <TextField
          disabled={disabled}
          inputProps={{ "aria-label": label }}
          className={classes.taskPropertyField}
          value={value}
          onChange={
            onChange ? (event) => onChange(event.target.value) : undefined
          }
          variant="standard"
        />
      </Grid>
    </Grid>
  );
};

export default TaskPropertyItem;
