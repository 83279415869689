import React from "react";
import { AccordionSummary, Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  name: string;
  extraText?: string | number;
  onAction?: () => void;
  actionIcon?: () => JSX.Element;
  icon?: () => JSX.Element;
  placeholder?: string;
  draggable?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

const ItemAccordionSummary: React.FC<IProps> = ({
  name,
  extraText,
  placeholder,
  onAction,
  actionIcon,
  icon,
  readOnly,
  draggable = false,
  dragHandleProps,
}) => {
  const styledIcon = icon
    ? React.cloneElement(icon(), {
        className: classes.itemIcon,
      })
    : null;

  const styledActionIcon = actionIcon ? (
    React.cloneElement(actionIcon(), {
      className: classes.mediumGrey,
    })
  ) : (
    <AddIcon className={classes.mediumGrey} />
  );

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon className={classes.mediumGrey} />}
      className={classes.itemAccordionSummary}
    >
      <Grid container>
        <Grid item xs={11}>
          <Grid container alignItems="center" className={classes.fullHeight}>
            {styledIcon}
            <Typography variant="h6" style={{ paddingLeft: 12 }}>
              {!name && (
                <span className={classes.placeholderText}>
                  {placeholder || "Unnamed"}
                </span>
              )}
              {name}{" "}
              <span className={classes.mediumGrey}>
                {extraText ? `(${extraText})` : null}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          {!readOnly && (
            <Grid container alignItems="flex-end" justifyContent="flex-end">
              {onAction && (
                <IconButton
                  aria-label={actionIcon ? "Action" : "Add"}
                  id={`add_${name?.toLowerCase()}`}
                  onFocus={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    onAction();
                    e.stopPropagation();
                  }}
                >
                  {styledActionIcon}
                </IconButton>
              )}
              {draggable && (
                <div {...dragHandleProps}>
                  <IconButton style={{ cursor: "grab" }}>
                    {<DragIndicatorIcon className={classes.mediumGrey} />}
                  </IconButton>
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};

export default ItemAccordionSummary;
