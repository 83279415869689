import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface IProps {
  title: string;
  message?: string;
  body?: JSX.Element;
  open: boolean;
  onClose: () => void;
  action: () => void;
  cancelText?: string;
  confirmationText?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  confirmDisabled?: boolean;
}

export const DialogAlert: React.FC<IProps> = ({
  title,
  message,
  body,
  open,
  onClose,
  action,
  cancelText,
  confirmationText: okText,
  maxWidth,
  confirmDisabled = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={title}
      aria-describedby={message}
      BackdropProps={{ invisible: true }}
      PaperProps={{
        elevation: 1,
      }}
      maxWidth={maxWidth}
    >
      {title && title.length > 0 && (
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      )}
      <DialogContent>
        {message && message.length > 0 && (
          <DialogContentText id="alert-dialog-description-message">
            {message}
          </DialogContentText>
        )}
        {body && <div id="alert-dialog-description-body">{body}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancelText ?? "Cancel"}
        </Button>
        <Button
          disabled={confirmDisabled}
          onClick={() => {
            onClose();
            action();
          }}
          color="primary"
          id="dialog-confirm"
          autoFocus
        >
          {okText ?? "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
