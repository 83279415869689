import { blueGrey, grey } from "@mui/material/colors";
import bugIcon from "../src/Common/Assets/Images/bug_icon.svg";
import theme from "./App/App.Theme";

const PREFIX = "AppStyles";

export const classes = {
  root: `${PREFIX}-root`,
  grow: `${PREFIX}-grow`,
  menuButton: `${PREFIX}-menuButton`,
  content: `${PREFIX}-content`,
  link: `${PREFIX}-link`,
  active: `${PREFIX}-active`,
  whiteText: `${PREFIX}-whiteText`,
  logoImg: `${PREFIX}-logoImg`,
  margin: `${PREFIX}-margin`,
  marginTop: `${PREFIX}-marginTop`,
  marginLeft: `${PREFIX}-marginLeft`,
  marginRight: `${PREFIX}-marginRight`,
  invalid: `${PREFIX}-invalid`,
  badge: `${PREFIX}-badge`,
  message: `${PREFIX}-message`,
  info: `${PREFIX}-info`,
  form: `${PREFIX}-form`,
  demoPopup: `${PREFIX}-demoPopup`,
  dragging: `${PREFIX}-dragging`,
  selected: `${PREFIX}-selected`,
  selectedText: `${PREFIX}-selectedText`,
  centerText: `${PREFIX}-centerText`,
  centerTextRel: `${PREFIX}-centerTextRel`,
  jobPreviewEditor: `${PREFIX}-jobPreviewEditor`,
  headerTiles: `${PREFIX}-headerTiles`,
  headerTileIcon: `${PREFIX}-headerTileIcon`,
  tileText: `${PREFIX}-tileText`,
  paper: `${PREFIX}-paper`,
  draggable: `${PREFIX}-draggable`,
  draggingOver: `${PREFIX}-draggingOver`,
  invalidDrag: `${PREFIX}-invalidDrag`,
  alignRight: `${PREFIX}-alignRight`,
  smallPadding: `${PREFIX}-smallPadding`,
  toolTip: `${PREFIX}-toolTip`,
  fullHeight: `${PREFIX}-fullHeight`,
  mediumGrey: `${PREFIX}-mediumGrey`,
  darkGrey: `${PREFIX}-darkGrey`,
  helpPaper: `${PREFIX}-helpPaper`,
  helpText: `${PREFIX}-helpText`,
  versionButton: `${PREFIX}-versionButton`,
  table: `${PREFIX}-table`,
  runtimeWrapper: `${PREFIX}-runtimeWrapper`,
  nameEditAvatar: `${PREFIX}-nameEditAvatar`,
  keyEditAvatar: `${PREFIX}-keyEditAvatar`,
  nameReadOnlyAvatar: `${PREFIX}-nameReadOnlyAvatar`,
  toolbar: `${PREFIX}-toolbar`,
  versionPaper: `${PREFIX}-versionPaper`,
  versionEnabledPaper: `${PREFIX}-versionEnabledPaper`,
  debugSwitch: `${PREFIX}-debugSwitch`,
  subjectContainer: `${PREFIX}-subjectContainer`,
  subjectTitle: `${PREFIX}-subjectTitle`,
  subjectTitleOpen: `${PREFIX}-subjectTitleOpen`,
  subjectTitleClosed: `${PREFIX}-subjectTitleClosed`,
  subjectTitleBottom: `${PREFIX}-subjectTitleBottom`,
  subjectText: `${PREFIX}-subjectText`,
  taskContainer: `${PREFIX}-taskContainer`,
  taskHighlight: `${PREFIX}-taskHighlight`,
  taskMain: `${PREFIX}-taskMain`,
  taskTitle: `${PREFIX}-taskTitle`,
  taskDescription: `${PREFIX}-taskDescription`,
  taskHeading: `${PREFIX}-taskHeading`,
  taskBody: `${PREFIX}-taskBody`,
  taskBodyPlaceholder: `${PREFIX}-taskBodyPlaceholder`,
  taskStatus: `${PREFIX}-taskStatus`,
  taskStatusColumnItem: `${PREFIX}-taskStatusColumnItem`,
  taskStatusIcon: `${PREFIX}-taskStatusIcon`,
  taskStatusList: `${PREFIX}-taskStatusList`,
  taskStatusListItem: `${PREFIX}-taskStatusListItem`,
  editAssigneeButton: `${PREFIX}-editAssigneeButton`,
  taskStatusButtonContainer: `${PREFIX}-taskStatusButtonContainer`,
  taskStatusDivider: `${PREFIX}-taskStatusDivider`,
  taskIcon: `${PREFIX}-taskIcon`,
  taskEditIcon: `${PREFIX}-taskEditIcon`,
  taskItem: `${PREFIX}-taskItem`,
  taskPropertyLabel: `${PREFIX}-taskPropertyLabel`,
  taskPropertyField: `${PREFIX}-taskPropertyField`,
  taskDivider: `${PREFIX}-taskDivider`,
  taskStateButtonContainer: `${PREFIX}-taskStateButtonContainer`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
  buttonSave: `${PREFIX}-buttonSave`,
  buttonCancel: `${PREFIX}-buttonCancel`,
  buttonDelete: `${PREFIX}-buttonDelete`,
  popupPaper: `${PREFIX}-popupPaper`,
  popupTextField: `${PREFIX}-popupTextField`,
  noBackground: `${PREFIX}-noBackground`,
  filterBar: `${PREFIX}-filterBar`,
  filter: `${PREFIX}-filter`,
  filterOptionIcon: `${PREFIX}-filterOptionIcon`,
  filterOptionLabelItem: `${PREFIX}-filterOptionLabelItem`,
  tasksTitle: `${PREFIX}-tasksTitle`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  loadingInnerContainer: `${PREFIX}-loadingInnerContainer`,
  warning: `${PREFIX}-warning`,
  multiSelectMenu: `${PREFIX}-multiSelectMenu`,
  vaultSecretKeyWarning: `${PREFIX}-vaultSecretKeyWarning`,
  inner: `${PREFIX}-inner`,
  banner: `${PREFIX}-banner`,
  customEvent: `${PREFIX}-customEvent`,
  groupEvent: `${PREFIX}-groupEvent`,
  aireframeEvent: `${PREFIX}-aireframeEvent`,
  task: `${PREFIX}-task`,
  externalTask: `${PREFIX}-externalTask`,
  notification: `${PREFIX}-notification`,
  externalNotification: `${PREFIX}-externalNotification`,
  disabled: `${PREFIX}-disabled`,
  node: `${PREFIX}-node`,
  text: `${PREFIX}-text`,
  errorContainer: `${PREFIX}-errorContainer`,
  container: `${PREFIX}-container`,
  itemContainer: `${PREFIX}-itemContainer`,
  extraPadding: `${PREFIX}-extraPadding`,
  itemAccordionDetail: `${PREFIX}-itemAccordionDetail`,
  itemAccordionDetailEmpty: `${PREFIX}-itemAccordionDetailEmpty`,
  itemAccordionEmptyIcon: `${PREFIX}-itemAccordionEmptyIcon`,
  itemAccordionSummary: `${PREFIX}-itemAccordionSummary`,
  emptyText: `${PREFIX}-emptyText`,
  subtitle: `${PREFIX}-subtitle`,
  subsubtitle: `${PREFIX}-subsubtitle`,
  itemChildBorder: `${PREFIX}-itemChildBorder`,
  itemChild: `${PREFIX}-itemChild`,
  item: `${PREFIX}-item`,
  placeholderText: `${PREFIX}-placeholderText`,
  itemIcon: `${PREFIX}-itemIcon`,
  errorMessage: `${PREFIX}-errorMessage`,
  noMargin: `${PREFIX}-noMargin`,
  transparentBorderless: `${PREFIX}-transparentBorderless`,
  previewText: `${PREFIX}-previewText`,
};

export const appStyles = {
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.grow}`]: {
    margin: theme.spacing(1),
    flexGrow: 1,
  },

  [`& .${classes.menuButton}`]: {
    marginLeft: -12,
    marginRight: 20,
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.link}`]: {
    cursor: "pointer",
    textDecoration: "none",
    color: "black",
  },

  [`& .${classes.active}`]: {
    color: theme.palette.secondary.main,
  },

  [`& .${classes.whiteText}`]: {
    color: "white",
  },

  [`& .${classes.logoImg}`]: {
    height: "1.5em",
    width: "1.5em",
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.marginTop}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.marginLeft}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.marginRight}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.invalid}`]: {
    backgroundColor: theme.palette.error.main,
    color: "white",
  },

  [`& .${classes.badge}`]: {
    top: 5,
    right: 3,
  },

  [`& .${classes.message}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.info}`]: {
    backgroundColor: "#558b2f",
    color: "white",
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.demoPopup}`]: {
    width: "750px",
    maxWidth: "750px",
    position: "absolute",
    bottom: "24px",
  },

  [`& .${classes.dragging}`]: {
    borderWidth: "1px",
    borderColour: "red",
  },

  [`& .${classes.selected}`]: {
    backgroundColor: theme.palette.primary.light,
  },

  [`& .${classes.selectedText}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.centerText}`]: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },

  [`& .${classes.centerTextRel}`]: {
    position: "relative",
    transform: "translate(35%, 300%)",
  },

  [`& .${classes.jobPreviewEditor}`]: {
    overflow: "auto",
  },

  [`& .${classes.headerTiles}`]: {
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: `5px solid ${theme.palette.secondary.main}`,
  },

  [`& .${classes.headerTileIcon}`]: {
    fontSize: 40,
    color: theme.palette.primary.main,
    paddingRight: 5,
  },

  [`& .${classes.tileText}`]: {
    fontSize: 20,
    color: theme.palette.grey["400"],
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.draggable}`]: {
    cursor: "move",
  },

  [`& .${classes.draggingOver}`]: {
    backgroundColor: `gainsboro`,
  },

  [`& .${classes.invalidDrag}`]: {
    backgroundColor: theme.palette.error.light,
  },

  [`& .${classes.alignRight}`]: {
    textAlign: "right",
  },

  [`& .${classes.smallPadding}`]: {
    padding: "2px",
  },

  [`& .${classes.toolTip}`]: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.mediumGrey}`]: {
    color: blueGrey[500],
    fill: blueGrey[500],
  },

  [`& .${classes.darkGrey}`]: {
    color: blueGrey[700],
  },

  [`& .${classes.helpPaper}`]: {
    maxWidth: 500,
  },

  [`& .${classes.helpText}`]: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },

  [`& .${classes.versionButton}`]: {
    padding: theme.spacing(0.5),
  },

  [`& .${classes.table}`]: {
    borderRadius: theme.spacing(2),
    overflow: "hidden",
    maxWidth: 1400,
    margin: "auto",
  },

  [`& .${classes.runtimeWrapper}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.nameEditAvatar}`]: {
    background: blueGrey[100],
  },

  [`& .${classes.keyEditAvatar}`]: {
    background: blueGrey[100],
  },

  [`& .${classes.nameReadOnlyAvatar}`]: {},
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.versionPaper}`]: {
    width: 350,
    borderRadius: theme.spacing(1.5),
    overflow: "hidden",
    padding: theme.spacing(2),
  },

  [`& .${classes.versionEnabledPaper}`]: {
    width: 500,
    borderRadius: theme.spacing(1.5),
    overflow: "hidden",
    padding: theme.spacing(2),
  },

  [`& .${classes.debugSwitch}`]: {
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.grey[500],
      backgroundImage: `url(${bugIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "2.2rem 1.275rem",
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.primary.main,
      opacity: 100,
    },
  },

  [`& .${classes.subjectContainer}`]: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    overflow: "hidden",
    background: blueGrey[50],
  },

  [`& .${classes.subjectTitle}`]: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: "none",
    overflowWrap: "anywhere",
  },

  [`& .${classes.subjectTitleOpen}`]: {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
  },

  [`& .${classes.subjectTitleClosed}`]: {
    color: theme.palette.secondary.main,
    background: blueGrey[50],
  },

  [`& .${classes.subjectTitleBottom}`]: {
    color: theme.palette.secondary.main,
    background: theme.palette.common.white,
  },

  [`& .${classes.subjectText}`]: {
    color: grey[600],
  },

  [`& .${classes.taskContainer}`]: {
    margin: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    overflow: "hidden",
    position: "relative",
  },

  [`& .${classes.taskHighlight}`]: {
    width: theme.spacing(1.5),
    height: "100%",
    position: "absolute",
    background: theme.palette.secondary.main,
  },

  [`& .${classes.taskMain}`]: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.taskTitle}`]: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    fontSize: "13pt",
  },

  [`& .${classes.taskDescription}`]: {
    color: grey[600],
    fontSize: "11pt",
  },

  [`& .${classes.taskHeading}`]: {
    color: grey[500],
    fontSize: "10pt",
    height: "15pt",
    marginBottom: -2,
    textAlign: "start",
  },

  [`& .${classes.taskBody}`]: {
    color: theme.palette.secondary.main,
    fontSize: "11pt",
    overflowWrap: "anywhere",
  },

  [`& .${classes.taskBodyPlaceholder}`]: {
    color: grey[400],
    fontSize: "10pt",
    textAlign: "left",
    fontStyle: "italic",
  },

  [`& .${classes.taskStatus}`]: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: "28pt",
    borderRadius: "14pt",
    textTransform: "none",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },

  [`& .${classes.taskStatusColumnItem}`]: {
    width: 160,
  },

  [`& .${classes.taskStatusIcon}`]: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.taskStatusList}`]: {
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(2),
    width: "200pt",
  },

  [`& .${classes.taskStatusListItem}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    "& li": {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      overflow: "hidden",
      color: blueGrey[600],
    },
    "& li:hover": {
      background: blueGrey[50],
    },
    "& li.Mui-selected": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& li.Mui-selected:hover": {
      background: theme.palette.primary.dark,
    },
  },

  [`& .${classes.editAssigneeButton}`]: {
    textTransform: "none",
    display: "inline-block",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    justifyContent: "flex-start",
    width: "100%",
    textAlign: "left",
  },

  [`& .${classes.taskStatusButtonContainer}`]: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },

  [`& .${classes.taskStatusDivider}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.taskIcon}`]: {
    color: theme.palette.secondary.main,
    paddingRight: theme.spacing(0.2),
  },

  [`& .${classes.taskEditIcon}`]: {
    margin: "auto 0",
  },

  [`& .${classes.taskItem}`]: {
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
  },

  [`& .${classes.taskPropertyLabel}`]: {
    color: theme.palette.secondary.main,
    margin: theme.spacing(1),
    textTransform: "capitalize",
  },

  [`& .${classes.taskPropertyField}`]: {
    width: "100%",
    margin: theme.spacing(1),
    marginRight: "0",
  },

  [`& .${classes.taskDivider}`]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.taskStateButtonContainer}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.buttonContainer}`]: {
    background: "transparent",
    borderRadius: 0,
    justifyContent: "flex-end",
    display: "flex",
  },

  [`& .${classes.button}`]: {
    fontSize: "11pt",
    marginLeft: theme.spacing(2),
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.buttonSave}`]: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      background: theme.palette.secondary.light,
    },
    "&:disabled": {
      color: grey[500],
      background: grey[200],
    },
  },

  [`& .${classes.buttonCancel}`]: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    border: "solid 1px",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },

  [`& .${classes.buttonDelete}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    border: "solid 1px",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },

  [`& .${classes.popupPaper}`]: {
    width: 300,
    borderRadius: theme.spacing(1.5),
    overflow: "hidden",
    padding: theme.spacing(2),
  },

  [`& .${classes.popupTextField}`]: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.noBackground}`]: {
    background: "none",
  },

  [`& .${classes.filterBar}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.filter}`]: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },

  [`& .${classes.filterOptionIcon}`]: {
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.filterOptionLabelItem}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.tasksTitle}`]: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },

  [`& .${classes.loadingContainer}`]: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },

  [`& .${classes.loadingInnerContainer}`]: {
    position: "relative",
    padding: "32px",
    width: "100%",
    maxWidth: "640px",
    textAlign: "center",
  },

  [`& .${classes.warning}`]: {
    color: `${theme.palette.warning.main} !important`,
  },

  [`& .${classes.multiSelectMenu}`]: {
    minWidth: "234px",
  },

  [`& .${classes.vaultSecretKeyWarning}`]: {
    color: "rgb(180, 113, 5)",
    fontSize: "1.5em",
    marginRight: 5,
    verticalAlign: "text-bottom",
  },

  [`&.${classes.inner}`]: {
    padding: "20px 16px",
    width: "230px",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.banner}`]: {
    padding: "1px 16px",
    width: "230px",
  },

  [`& .${classes.customEvent}`]: {
    borderColor: theme.palette.primary.light,
  },

  [`& .${classes.groupEvent}`]: {
    borderColor: theme.palette.primary.dark,
  },

  [`& .${classes.aireframeEvent}`]: {
    borderColor: theme.palette.primary.main,
  },

  [`& .${classes.task}`]: {
    borderColor: theme.palette.secondary.light,
  },

  [`& .${classes.externalTask}`]: {
    borderColor: theme.palette.secondary.dark,
  },

  [`& .${classes.notification}`]: {
    borderColor: blueGrey[400],
  },

  [`& .${classes.externalNotification}`]: {
    borderColor: blueGrey[800],
  },

  [`& .${classes.disabled}`]: {
    backgroundColor: grey[300],
    borderStyle: "dashed dashed dashed solid",
  },

  [`& .${classes.node}`]: {
    borderWidth: "1px 1px 1px 16px",
    borderRadius: theme.spacing(1),
    color: blueGrey[900],
    paddingRight: "16px",
  },

  [`& .${classes.text}`]: {
    textAlign: "center",
  },

  [`& .${classes.errorContainer}`]: {
    background: grey[100],
    padding: theme.spacing(2),
    justifyContent: "center",
  },
  [`&.${classes.container}`]: {
    background: blueGrey[50],
    padding: theme.spacing(2),
  },

  [`& .${classes.itemContainer}`]: {
    background: "#FFF",
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: "hidden",
  },

  [`& .${classes.extraPadding}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.itemAccordionDetail}`]: {
    flexDirection: "column",
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    marginTop: 0,
    background: blueGrey[200],
    borderRadius: theme.spacing(1),
    overflow: "hidden",
  },

  [`& .${classes.itemAccordionDetailEmpty}`]: {
    background: theme.palette.common.white,
    border: "solid 1px",
    borderColor: blueGrey[200],
    padding: 0,
    textTransform: "none",
  },

  [`& .${classes.itemAccordionEmptyIcon}`]: {
    margin: theme.spacing(0.5),
  },

  [`& .${classes.itemAccordionSummary}`]: {
    flexDirection: "row-reverse",
    height: 64,
  },

  [`& .${classes.emptyText}`]: {
    width: "100%",
    textAlign: "center",
    color: blueGrey[500],
    textTransform: "none",
    fontSize: "12pt",
  },

  [`& .${classes.fullHeight}`]: {
    height: "100%",
  },

  [`& .${classes.subtitle}`]: {
    paddingTop: theme.spacing(2),
  },

  [`& .${classes.subsubtitle}`]: {
    fontSize: "14pt",
    paddingBottom: theme.spacing(1),
    color: blueGrey[700],
  },

  [`& .${classes.itemChildBorder}`]: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    marginTop: 0,
    borderStyle: "solid",
    borderWidth: theme.spacing(1),
    borderColor: blueGrey[50],
    borderRadius: theme.spacing(1),
    overflow: "hidden",
  },

  [`& .${classes.itemChild}`]: {
    background: blueGrey[50],
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    marginLeft: 0,
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.item}`]: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: "#FFF",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    "&:last-child": {
      marginBottom: 0,
    },
  },

  [`& .${classes.placeholderText}`]: {
    fontStyle: "italic",
    color: blueGrey[300],
  },

  [`& .${classes.itemIcon}`]: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.errorMessage}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.noMargin}`]: {
    margin: 0,
  },

  [`& .${classes.transparentBorderless}`]: {
    background: "transparent !important",
    borderRadius: "0 !important",
  },

  [`& .${classes.previewText}`]: {
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    verticalAlign: "middle",
  },
};
