import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from "@mui/material";
import React from "react";

interface IProps {
  title: string;
  body: JSX.Element;
  open: boolean;
  onClose: () => void;
  action: () => void;
  confirmationText: string;
  confirmDisabled?: boolean;
}

const ConfirmButton = styled(Button)(() => ({
  color: "white",
  background: "#d32f2f",
  "&:hover": {
    background: "#bf2f2f",
  },
}));

export const DeleteAlert: React.FC<IProps> = ({
  title,
  body,
  open,
  onClose,
  action,
  confirmationText,
  confirmDisabled = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={title}
      BackdropProps={{ invisible: true }}
      PaperProps={{
        elevation: 1,
      }}
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        <span style={{ fontWeight: "700" }}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <div id="alert-dialog-description-body">{body}</div>
      </DialogContent>
      <DialogActions style={{ padding: "24px", paddingTop: "8px" }}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item>
            <Button variant="contained" onClick={onClose} color="primary">
              Abort
            </Button>
          </Grid>
          <Grid item>
            <ConfirmButton
              variant="contained"
              disabled={confirmDisabled}
              onClick={() => {
                onClose();
                action();
              }}
              id="dialog-confirm"
            >
              {confirmationText}
            </ConfirmButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
