import { TaskType } from "../Enums/TaskType";

const TaskTypeDisplayName: Record<TaskType, string> = {
  [TaskType.Manual]: "Manual",
  [TaskType.Http]: "Http",
  [TaskType.Email]: "Email",
  [TaskType.AireFrameCreateForm]: "AireFrame - Create Form",
};

export const TaskTypeNameProvider = (taskType: TaskType): string => {
  return TaskTypeDisplayName[taskType];
};
