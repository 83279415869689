import { Navigate, Routes, Route } from "react-router-dom";
import { AuthenticatedRoute } from "./Components/AuthenticatedRoute/AuthenticatedRoute";
import DefinitionsList from "./Components/Definitions/DefinitionsList";
import IFrame from "./Components/IFrame/IFrame";
import RuntimeWrapper from "./Components/RuntimeWrapper/RuntimeWrapper";
import Streams from "./Components/EventDefinitions/EventDefinitions";
import EventLog from "./Components/EventLog/EventLog";
import WorkflowLogs from "./Components/WorkflowLogs/WorkflowLogs";
import Repositories from "./Components/Repositories/Repositories";
import { AbilityContextProvider } from "./Components/Authorisation/AbilityContext";
import Home from "./Components/Home";
import EventDefinitionGroups from "./Components/EventDefinitionGroups/EventDefinitionGroups";
import { useAppContext } from "./App/App.Context";

const Routing = (): JSX.Element => {
  const { permissionsClaim } = useAppContext();

  return (
    <AbilityContextProvider permissionsClaim={permissionsClaim}>
      <Routes>
        <Route path="/" element={<AuthenticatedRoute />}>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/WorkflowDefinitions" element={<DefinitionsList />} />
          <Route path="/EventDefinitions" element={<Streams />} />
          <Route
            path="/EventDefinitionGroups"
            element={<EventDefinitionGroups />}
          />
          <Route path="/Tasks" element={<RuntimeWrapper />} />
          <Route path="/EventLog" element={<EventLog />} />
          <Route path="/WorkflowLogs" element={<WorkflowLogs />} />
          <Route path="/RepoManagement" element={<Repositories />} />
          <Route
            path="/Api-Documentation"
            element={
              <IFrame
                url={`/swagger-documentation/index.html`}
                queryString={""}
              />
            }
          />
        </Route>
      </Routes>
    </AbilityContextProvider>
  );
};

export default Routing;
