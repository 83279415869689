import moment from "moment";

export const isValidDateTime = (value: string): boolean => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

export const formatDate = (input: string): string => {
  if (!isValidDateTime(input)) return "";

  const date = moment(input);

  return date.format("YYYY-MM-DDTHH:mm:ss");
};
