import React from "react";
import { Accordion, AccordionDetails } from "@mui/material";
import ItemAccordionSummary from "../DefinitionDetail/ItemAccordionSummary";
import classNames from "classnames";
import ItemAccordionEmptyButton from "./ItemAccordionEmptyButton";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps, React.PropsWithChildren {
  name: string;
  count?: number;
  onAdd?: () => void;
  icon?: () => JSX.Element;
}

const ItemAccordion: React.FC<IProps> = ({
  name,
  count,
  onAdd,
  icon,
  children,
  readOnly,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const onDetailAdd = () => {
    onAdd && onAdd();
    setExpanded(true);
  };

  return (
    <Accordion
      expanded={expanded}
      id={name.toLowerCase()}
      elevation={0}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <ItemAccordionSummary
        name={name}
        extraText={count}
        onAction={onAdd ? onDetailAdd : undefined}
        icon={icon}
        readOnly={readOnly}
      />
      <AccordionDetails
        className={classNames(classes.itemAccordionDetail, {
          [`${classes.itemAccordionDetailEmpty}`]: count === 0,
        })}
      >
        {count === 0 && !!onAdd ? (
          <ItemAccordionEmptyButton
            name={name}
            onAdd={onDetailAdd}
            readOnly={readOnly}
          />
        ) : (
          children
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ItemAccordion;
