import {
  Button,
  Grid,
  Paper,
  Typography,
  Tooltip,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { isPatient, ISubject } from "../Interfaces/ISubject";
import moment from "moment";
import AssigneeIcon from "@mui/icons-material/PersonOutline";
import PatientIcon from "@mui/icons-material/Person";
import PolylineIcon from "@mui/icons-material/PolylineOutlined";
import CategoryIcon from "@mui/icons-material/CategoryOutlined";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import ContractIcon from "@mui/icons-material/ExpandLess";
import DueIcon from "@mui/icons-material/Schedule";
import TaskInfo from "./TaskInfo";
import TaskState from "./State/TaskState";
import classNames from "classnames";
import TaskStatusSelect from "./TaskStatusSelect";
import { useTasksService } from "../Hooks/useTasksService";
import { ITaskInstance } from "../Interfaces/ITaskInstance";
import PopupTextEdit from "./PopupTextEdit/PopupTextEdit";
import Loading from "../Components/Loading/Loading";
import SnackbarAlert from "./SnackbarAlert";
import { classes } from "../App.Styles";
import { TaskType } from "../Common/Enums/TaskType";
import TaskPropertyItem from "./Property/TaskPropertyItem";

interface Props {
  task: ITaskInstance;
  subject: ISubject;
  onChange: () => void;
}

const TaskItem: React.FC<Props> = ({ task, subject, onChange }) => {
  const [propertiesOpen, setPropertiesOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const subjectIsPatient = isPatient(subject);
  const { updateStatus, updateState, updateAssignee } = useTasksService();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const showProperties =
    Object.keys(task.state).length > 0 ||
    task.activityContext.to ||
    task.activityContext.url;

  const onStatusUpdate: (status: string) => Promise<boolean> = async (
    status,
  ) => {
    setLoading(true);
    try {
      await updateStatus(task.id, status);
      onChange();
      setSnackbarMessage("Task Status Saved");
      return true;
    } catch (e) {
      const errorContent = getErrorContent(e);
      if (errorContent) setSnackbarMessage(`Error: ${errorContent}`);
      else setSnackbarMessage(`Error: ${e}`);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getErrorContent = (e: any) => {
    if (e instanceof Error) {
      // check if there is an error message
      const regex = /"body":\s*"([^"]+)"/;
      const match = e.message.match(regex);

      return match && match[1] ? match[1] : null;
    }
    return null;
  };

  const onStateUpdate: (
    state: { key: string; value: string }[],
  ) => void = async (state) => {
    setLoading(true);
    task.state = state;
    try {
      await updateState(task.id, state);
      setSnackbarMessage(`Task Saved`);
    } catch (e) {
      const errorContent = getErrorContent(e);
      if (errorContent) setSnackbarMessage(`Error: ${errorContent}`);
      else setSnackbarMessage(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const onAssigneeUpdate: (assigneeId: string) => void = async (assigneeId) => {
    setLoading(true);
    try {
      await updateAssignee(task.id, assigneeId).then(() => {
        onChange();
      });
    } catch (e) {
      const errorContent = getErrorContent(e);
      if (errorContent) setSnackbarMessage(`Error: ${errorContent}`);
      else setSnackbarMessage(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Loading visible={loading} />
      <Paper className={classes.taskContainer} elevation={0}>
        <div className={classes.taskHighlight} />
        <Grid container className={classes.taskMain}>
          <Grid item xs={3} md={4}>
            <Typography className={classes.taskTitle}>{task.name}</Typography>
            <Typography className={classes.taskDescription}>
              {task.description}
            </Typography>
          </Grid>
          <Grid item container alignItems="flex-start" xs={6} spacing={1}>
            <Grid item xs={12} md={6}>
              <TaskInfo
                label={subjectIsPatient ? "Patient" : "Subject"}
                body={subject.name ?? subject.id}
                tooltipTitle={subject.name ?? subject.id}
                icon={<PatientIcon className={classes.taskIcon} />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TaskInfo
                label="Due"
                body={
                  task.activityContext.due
                    ? moment(task.activityContext.due).format(
                        "DD-MMM-YYYY [at] HH:mm",
                      )
                    : null
                }
                tooltipTitle={moment(task.activityContext.due).format(
                  "DD-MMM-YYYY [at] HH:mm",
                )}
                icon={<DueIcon className={classes.taskIcon} />}
                placeholder="None"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {task.taskType === TaskType.Manual ? (
                <PopupTextEdit
                  name="Edit Assignee"
                  value={task.assigneeId}
                  onSave={(newName) => {
                    onAssigneeUpdate(newName);
                  }}
                  component={(onClick) => (
                    <Tooltip title="Edit Assignee">
                      <Button
                        onClick={onClick}
                        className={classes.editAssigneeButton}
                      >
                        <TaskInfo
                          label={
                            <span>
                              Assignee
                              <EditIcon
                                fontSize="inherit"
                                color="secondary"
                                sx={{ ml: 1 }}
                              />
                            </span>
                          }
                          body={task.assigneeId}
                          icon={<AssigneeIcon className={classes.taskIcon} />}
                          placeholder="None"
                        />
                      </Button>
                    </Tooltip>
                  )}
                />
              ) : (
                <TaskInfo
                  label="Assignee"
                  body={null}
                  icon={<AssigneeIcon className={classes.taskIcon} />}
                  placeholder="None"
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TaskInfo
                label={"Correlation ID"}
                body={task.correlationId}
                icon={<PolylineIcon className={classes.taskIcon} />}
                tooltipTitle={task.correlationId}
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <TaskInfo
                label={"Task Type"}
                body={task.taskType}
                icon={<CategoryIcon className={classes.taskIcon} />}
                tooltipTitle={task.taskType}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="flex-end"
            xs={3}
            md={2}
          >
            <TaskStatusSelect
              taskStatus={task.activityContext.status}
              taskType={task.taskType}
              onUpdate={onStatusUpdate}
            />
            <Grid item style={{ flexGrow: 1 }} />
            {showProperties && (
              <Button
                className={classNames(
                  classes.subjectTitle,
                  classes.subjectTitleBottom,
                  classes.taskStatusColumnItem,
                )}
                onClick={() => setPropertiesOpen(!propertiesOpen)}
              >
                Properties {propertiesOpen ? <ContractIcon /> : <ExpandIcon />}
              </Button>
            )}
          </Grid>
          {propertiesOpen && (
            <>
              {Object.keys(task.state).length > 0 && (
                <TaskState
                  task={task}
                  onUpdate={onStateUpdate}
                  onCancel={() => setPropertiesOpen(!propertiesOpen)}
                />
              )}
              {task.activityContext.to && (
                <>
                  <Grid item xs={12} className={classes.taskDivider}>
                    <Divider />
                  </Grid>
                  <Grid item container xs={12}>
                    <TaskPropertyItem
                      label="Email To"
                      value={task.activityContext.to}
                      disabled={true}
                    />
                  </Grid>
                </>
              )}
              {task.activityContext.url && (
                <>
                  <Grid item xs={12} className={classes.taskDivider}>
                    <Divider />
                  </Grid>
                  <Grid item container xs={12}>
                    <TaskPropertyItem
                      label="Url"
                      value={task.activityContext.url}
                      disabled={true}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Paper>
      <SnackbarAlert
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage("")}
        colour={snackbarMessage.includes("Error:") ? "error" : "success"}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
};

export default TaskItem;
