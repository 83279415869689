import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import FilterBase from "./FilterBase";
import { TaskStatusNameProvider } from "../../Common/Providers/TaskStatusNameProvider";
import { TaskStatus } from "../../Common/Enums/TaskStatus";

interface IProps {
  value?: TaskStatus;
  name: string;
  tooltip: string;
  onChange: (status: TaskStatus | undefined) => void;
}

const TaskStatusFilter: React.FC<IProps> = ({
  value,
  name,
  tooltip,
  onChange,
}) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const onDelete = () => {
    onChange(undefined);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const handleChange = (value: TaskStatus) => {
    onChange(value);
    handleClose();
  };

  return (
    <>
      <FilterBase
        value={value ? TaskStatusNameProvider(value) : undefined}
        name={name}
        onClick={handleClick}
        onDelete={onDelete}
        tooltip={tooltip}
      />
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={!!menuAnchor}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChange(TaskStatus.New)}>
          {TaskStatusNameProvider(TaskStatus.New)}
        </MenuItem>
        <MenuItem onClick={() => handleChange(TaskStatus.InProgress)}>
          {TaskStatusNameProvider(TaskStatus.InProgress)}
        </MenuItem>
        <MenuItem onClick={() => handleChange(TaskStatus.Completed)}>
          {TaskStatusNameProvider(TaskStatus.Completed)}
        </MenuItem>
        <MenuItem onClick={() => handleChange(TaskStatus.Cancelled)}>
          {TaskStatusNameProvider(TaskStatus.Cancelled)}
        </MenuItem>
        <MenuItem onClick={() => handleChange(TaskStatus.Blocked)}>
          {TaskStatusNameProvider(TaskStatus.Blocked)}
        </MenuItem>
        <MenuItem onClick={() => handleChange(TaskStatus.Failed)}>
          {TaskStatusNameProvider(TaskStatus.Failed)}
        </MenuItem>
      </Menu>
    </>
  );
};

export default TaskStatusFilter;
