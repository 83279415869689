export enum AireFrameEventType {
  SubjectCreated = "subjectcreated",
  SubjectUpdated = "subjectupdated",
  SubjectAssignedToStructuralEntity = "subjectassignedtostructuralentity",
  SubjectRemovedFromStructuralEntity = "subjectremovedfromstructuralentity",
}

export const AireFrameEventTypeDisplayNames = {
  [AireFrameEventType.SubjectCreated]: "Subject Created",
  [AireFrameEventType.SubjectUpdated]: "Subject Updated",
  [AireFrameEventType.SubjectAssignedToStructuralEntity]: "Subject Assigned",
  [AireFrameEventType.SubjectRemovedFromStructuralEntity]: "Subject Removed",
};
