import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { JsonEditor } from "../../Editors";
import { Alert, Grid, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import Loading from "../../Loading/Loading";
import SnackbarAlert from "../../SnackbarAlert";

interface IProps {
  show: boolean;
  content: string;
  error: string;
  loading: boolean;
  snackbarOpen: boolean;
  info?: string;
  snackbarMessage: string;
  onChange: (input: string) => void;
  onClose: () => void;
  onSubmit: () => void;
  snackbarOnClose: () => void;
}

const ImportModal: React.FC<IProps> = (props: IProps) => {
  const {
    show,
    content,
    loading,
    error,
    snackbarOpen,
    info,
    snackbarMessage,
    onChange,
    onClose,
    onSubmit,
    snackbarOnClose,
  } = props;

  const onCancel = () => {
    onChange("");
    onClose();
  };
  return (
    <>
      <Dialog open={show} onClose={onClose} fullWidth={true} maxWidth={"lg"}>
        <Loading visible={loading} />
        <DialogTitle>Import</DialogTitle>
        <DialogContent>
          {info && (
            <Grid container>
              <Grid>
                <Info color="secondary" style={{ marginRight: "10px" }} />
              </Grid>
              <Grid>
                <Typography paragraph>{info}</Typography>
              </Grid>
            </Grid>
          )}
          {error && <Alert severity={"error"}>{error}</Alert>}
          <JsonEditor
            style={{ border: "1px solid" }}
            value={content}
            onChange={(value) => {
              onChange(value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Import
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen}
        onClose={snackbarOnClose}
        colour="success"
        message={snackbarMessage}
      />
    </>
  );
};

export default ImportModal;
