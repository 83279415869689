import { MarkerType, Edge, Node } from "reactflow";
import {
  EventType,
  EventTypeDisplayNames,
  parseRoutingKey,
} from "../../Common/Enums/EventType";
import { TaskType } from "../../Common/Enums/TaskType";
import classNames from "classnames";
import ITriggerDefinition from "../../Common/Interfaces/ITriggerDefinition";
import { getLayoutElements } from "./ChartLayout";
import IRelationshipTask from "../../Common/Interfaces/IRelationshipTask";
import { classes } from "../../App.Styles";
import NodeLabel from "./NodeLabel";

function getClassName(task: IRelationshipTask, parentDefinitionId: string) {
  const selectedClasses: string[] = [];

  if (!task.enabled) {
    selectedClasses.push(classes.disabled);
  }

  if (task.taskType === TaskType.Manual) {
    selectedClasses.push(
      task.workflowDefinitionId === parentDefinitionId
        ? classes.task
        : classes.externalTask,
    );
  } else {
    selectedClasses.push(
      task.workflowDefinitionId === parentDefinitionId
        ? classes.notification
        : classes.externalNotification,
    );
  }

  return classNames(selectedClasses);
}

interface GetNodesAndEdgesResponse {
  Nodes: Node[];
  Edges: Edge[];
}

function createEventNode(
  encounteredEvents: string[],
  trigger: ITriggerDefinition,
  title: string,
  className: string,
  nodes: Node[],
) {
  if (!encounteredEvents.includes(trigger.selectedEventKey)) {
    encounteredEvents.push(trigger.selectedEventKey);
    nodes.push({
      id: `${trigger.selectedEvent}_${trigger.selectedEventKey}`,
      data: {
        label: <NodeLabel title={title} subtitle={trigger.selectedEventKey} />,
      },
      className: classNames(classes.node, className),
      position: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      },
    });
  }
}

export function getNodesAndEdges(
  tasks: IRelationshipTask[],
  parentDefinitionId: string,
): GetNodesAndEdgesResponse {
  const encounteredCustomEvents: string[] = [];
  const encounteredGroupEvents: string[] = [];
  const encounteredAireFrameEvents: string[] = [];
  const nodes: Node[] = [];
  const edges: Edge[] = [];
  let counter = 0;
  for (const task of tasks) {
    const className = getClassName(task, parentDefinitionId);
    nodes.push({
      id: `${task.taskKey}_${task.workflowDefinitionVersion}`,
      data: {
        label: (
          <NodeLabel title={stripXPath(task.name)} subtitle={task.taskKey} />
        ),
      },
      className: classNames(classes.node, className),
      position: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
    });

    const allTriggers: ITriggerDefinition[] = [
      ...task.triggers,
      ...task.triggeredUpdates,
    ];

    for (const trigger of allTriggers) {
      let source: string;
      let edgeName: string;
      const routingKey = parseRoutingKey(trigger.routingKey);
      const taskName = EventTypeDisplayNames[routingKey.type];

      const eventTypeMap = {
        [EventType.Custom]: {
          encounteredEvents: encounteredCustomEvents,
          title: "Custom Event",
          className: classes.customEvent,
        },
        [EventType.Group]: {
          encounteredEvents: encounteredGroupEvents,
          title: "Event Group",
          className: classes.groupEvent,
        },
        [EventType.AireFrame]: {
          encounteredEvents: encounteredAireFrameEvents,
          title: "AireFrame Event",
          className: classes.aireframeEvent,
        },
      };

      if (
        trigger.selectedEvent === EventType.AireFrame ||
        trigger.selectedEvent === EventType.Group ||
        trigger.selectedEvent === EventType.Custom
      ) {
        const { encounteredEvents, title, className } =
          eventTypeMap[trigger.selectedEvent];
        createEventNode(encounteredEvents, trigger, title, className, nodes);
        source = `${trigger.selectedEvent}_${trigger.selectedEventKey}`;
        edgeName = trigger.selectedEventKey;
      } else {
        source = `${trigger.selectedTaskKey}_${trigger.selectedTriggerVersion}`;
        edgeName = `${taskName}`;
      }

      const isUpdate = Object.prototype.hasOwnProperty.call(trigger, "update");
      const hideLabel =
        trigger.selectedEvent === EventType.AireFrame ||
        trigger.selectedEvent === EventType.Group ||
        trigger.selectedEvent === EventType.Custom;

      if (source !== task.taskKey) {
        const target = `${task.taskKey}_${task.workflowDefinitionVersion}`;
        const duplicateEdges = edges.filter(
          (e) => e.source === source && e.target === target,
        ).length;

        edges.push({
          id: `edge_${counter++}`,
          source: source,
          target: target,
          type: "floating",
          label: hideLabel ? "" : edgeName + (isUpdate ? "*" : ""),
          style: isUpdate ? { stroke: "#52266E", strokeDasharray: "4" } : {},
          labelStyle: isUpdate ? { fontStyle: "italic" } : {},
          data: {
            labelPosition: duplicateEdges,
          },
          markerEnd: {
            type: MarkerType.Arrow,
          },
        });
      }
    }
  }

  getLayoutElements(nodes, edges);
  return {
    Edges: edges,
    Nodes: nodes,
  } as GetNodesAndEdgesResponse;
}

function stripXPath(part: string): string {
  return part.replace(/"/g, "").replace(/'/g, "");
}
