import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  IconButton,
  Tooltip,
  Typography,
  AlertColor,
} from "@mui/material";
import { Column, DataTable } from "../Tables/DataTable";
import { ITableRepositoryData } from "../../Common/Interfaces/ITableRepositoryData";
import EditIcon from "@mui/icons-material/Edit";
import RepoDetail from "./RepoDetail";
import CloseIcon from "@mui/icons-material/Cancel";
import { useRepositoryService } from "../../Common/Hooks/useRepositoryService";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncIcon from "@mui/icons-material/Sync";
import { DialogAlert } from "../DialogAlert";
import Wrapped from "../../Common/Helpers/WrappedTextHelpers";
import {
  AuthenticationType,
  IRepository,
} from "../../Common/Interfaces/IRepository";
import { HttpStatus } from "../../Common/Enums/HttpStatus";
import { IRepositoryResult } from "../../Common/Interfaces/IRepositoryResult";
import { HippityResponse } from "hippity";
import { WarningRounded } from "@mui/icons-material";
import classNames from "classnames";
import { VaultSecretKeyWarningMessage } from "./VaultSecretKeyWarningMessage";
import SnackbarAlert from "../SnackbarAlert";
import Loading from "../Loading/Loading";
import IVersionedTaskDefinition from "../../Common/Interfaces/IVersionedTaskDefinition";
import IWorkflowDefinition from "../../Common/Interfaces/IWorkflowDefinition";
import RepositoryAlert from "./RepositoryAlert";
import { ApiActionType } from "../../Common/Enums/ApiActionType";
import { classes } from "../../App.Styles";
import IEventDefinitionGroup from "../../Common/Interfaces/IEventDefinitionGroup";
import { CellProps } from "react-table";

const Repositories = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const service = useRepositoryService();
  const [repositories, setRepositories] = useState<ITableRepositoryData[]>([]);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColour, setSnackbarColour] =
    React.useState<AlertColor>("success");
  const [manualSyncOpen, setManualSyncOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ITableRepositoryData>();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
  const [confirmSyncOpen, setConfirmSyncOpen] = useState<boolean>(false);
  const [confirmUpdateOpen, setConfirmUpdateOpen] = useState<boolean>(false);
  const [workflowDefinitionsToDelete, setWorkflowDefinitionsToDelete] =
    useState<IWorkflowDefinition[]>([]);
  const [eventDefinitionGroupsToDelete, setEventDefinitionGroupsToDelete] =
    useState<IEventDefinitionGroup[]>([]);
  const [taskDefinitionsToDelete, setTaskDefinitionsToDelete] = useState<
    IVersionedTaskDefinition[]
  >([]);

  const [repositoriesFetchStatus, setRepositoriesFetchStatus] =
    useState<HttpStatus>(HttpStatus.Initial);

  useEffect(() => {
    getRepositories();

    // Cleanup
    return () => {
      setRepositories([]);
      setRepositoriesFetchStatus(HttpStatus.Initial);
      setLoading(true);
    };
  }, []);

  const getRepositories = async () => {
    setLoading(true);
    let httpStatus = HttpStatus.Initial;
    try {
      const response = await service.$get();
      setRepositories(response);
      httpStatus = HttpStatus.Success;
    } catch (e) {
      httpStatus = HttpStatus.Failure;
    }
    setRepositoriesFetchStatus(httpStatus);
    setLoading(false);
  };

  const getEmptyDataSourceMessage = (): string => {
    switch (repositoriesFetchStatus) {
      case HttpStatus.Success:
        return "No repositories to display. Select the + button to add a new repository.";
      case HttpStatus.Failure:
        return "Error fetching repositories, please try again.";
      default:
        return "";
    }
  };

  const showVaultSecretWarning = (
    vaultSecretKey: string | null,
    authenticationType: AuthenticationType,
  ): boolean => {
    return !vaultSecretKey && authenticationType != AuthenticationType.Public;
  };

  const columns: Column<ITableRepositoryData>[] = [
    {
      Header: "Name",
      id: "name",
      accessor: "name",
      Cell: ({ row }: { row: any }) => {
        const originalRow = row.original as ITableRepositoryData;
        return (
          <>
            {showVaultSecretWarning(
              originalRow.vaultSecretKey,
              originalRow.authenticationType,
            ) && (
              <Tooltip
                title={
                  <Typography style={{ fontSize: 14 }}>
                    {VaultSecretKeyWarningMessage(
                      originalRow.authenticationType,
                    )}
                  </Typography>
                }
                arrow
              >
                <WarningRounded
                  className={classNames(classes.vaultSecretKeyWarning)}
                />
              </Tooltip>
            )}
            {Wrapped(originalRow.name)}
          </>
        );
      },
    },
    {
      Header: "Branch",
      id: "branch",
      accessor: "branch",
      width: 100,
      Cell: ({ value }: { value: any }) => Wrapped(value),
    },
    {
      Header: "Synced",
      id: "synced",
      accessor: "synced",
      width: 70,
      Cell: ({ value }: CellProps<ITableRepositoryData, string>) => (
        <span>
          {new Intl.DateTimeFormat("en-GB", {
            dateStyle: "medium",
            timeStyle: "medium",
          }).format(new Date(value))}
        </span>
      ),
    },
    {
      Header: "Actions",
      id: "actions",
      width: 70,
      Cell: ({ row }: { row: any }) => {
        const originalRow = row.original as ITableRepositoryData;

        return (
          <span>
            <span {...row.getToggleRowExpandedProps()}>
              <IconButton className={classes.mediumGrey}>
                {row.isExpanded && originalRow.id != "" ? (
                  <CloseIcon />
                ) : (
                  <EditIcon />
                )}
              </IconButton>
            </span>
            <IconButton
              className={classes.mediumGrey}
              title="Manual Sync"
              onClick={() => {
                setSelectedItem(originalRow);
                setManualSyncOpen(true);
              }}
              disabled={showVaultSecretWarning(
                originalRow.vaultSecretKey,
                originalRow.authenticationType,
              )}
            >
              <SyncIcon />
            </IconButton>
            <IconButton
              className={classes.mediumGrey}
              title="Delete"
              onClick={() => {
                setSelectedItem(originalRow);
                setWorkflowDefinitionsToDelete(originalRow.workflowDefinitions);
                setEventDefinitionGroupsToDelete(
                  originalRow.eventDefinitionGroups,
                );
                setTaskDefinitionsToDelete([]);
                setConfirmDeleteOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
            {selectedItem && (
              <span>
                <RepositoryAlert
                  apiActionType={ApiActionType.Delete}
                  action={onRowDelete}
                  open={confirmDeleteOpen}
                  onClose={() => setConfirmDeleteOpen(false)}
                  repository={selectedItem}
                  taskDefinitionsToDelete={taskDefinitionsToDelete}
                  workflowDefinitionsToDelete={workflowDefinitionsToDelete}
                  eventDefinitionGroupsToDelete={eventDefinitionGroupsToDelete}
                />
              </span>
            )}
            {selectedItem && (
              <span>
                <DialogAlert
                  open={manualSyncOpen}
                  title={""}
                  message={`Are you sure you want to manually sync the repository: "${selectedItem.name}"?`}
                  onClose={() => setManualSyncOpen(false)}
                  action={() => onRowManualSync(selectedItem, false)}
                  body={undefined}
                />
              </span>
            )}
            {selectedItem && (
              <span>
                <RepositoryAlert
                  apiActionType={ApiActionType.Sync}
                  action={onRowManualSync}
                  open={confirmSyncOpen}
                  onClose={() => setConfirmSyncOpen(false)}
                  repository={selectedItem}
                  taskDefinitionsToDelete={taskDefinitionsToDelete}
                  workflowDefinitionsToDelete={workflowDefinitionsToDelete}
                  eventDefinitionGroupsToDelete={eventDefinitionGroupsToDelete}
                />
              </span>
            )}
          </span>
        );
      },
    },
  ];

  const onRowAdd = useCallback(
    async (item: ITableRepositoryData, resetAddRow: () => void) => {
      setLoading(true);
      try {
        const response = await service.create(item);
        const repositoryResult = response.body as IRepositoryResult;

        if (repositoryResult.success) {
          setSnackbar("Repository successfully added", "success");
          await getRepositories();
          resetAddRow();
        } else if (
          repositoryResult.errors.length > 0 ||
          repositoryResult.conflicts.length > 0
        ) {
          const message = `Error(s):\n- ${[
            ...((repositoryResult.errors as Array<string>) ?? []),
            ...((repositoryResult.conflicts as Array<string>) ?? []),
          ].join("\n- ")}`;
          setSnackbar(message, "error");
        } else {
          setSnackbar("Error: Creation failed", "error");
        }
      } catch (error) {
        setSnackbar((error as Error).message, "error");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const onRowUpdate = useCallback(
    async (item: ITableRepositoryData, force: boolean) => {
      await runSyncUpdateApiCall(
        () => service.update(item.id, item, force),
        ApiActionType.Update,
      );
    },
    [],
  );
  const onRowManualSync = useCallback(
    async (repository: IRepository, force: boolean) => {
      await runSyncUpdateApiCall(
        () => service.sync(repository.id, force),
        ApiActionType.Sync,
      );
    },
    [],
  );

  const onRowDelete = useCallback(
    async (repository: IRepository): Promise<void> => {
      setLoading(true);
      try {
        const response = await service.del(repository.id);
        switch (response.status) {
          case 204: {
            setSnackbar("Repository deleted", "success");
            await getRepositories();
            return;
          }
          default: {
            setSnackbar("Error: Deletion failed", "error");
            return;
          }
        }
      } catch (error) {
        setSnackbar((error as Error).message, "error");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const runSyncUpdateApiCall = useCallback(
    async (
      action: () => Promise<HippityResponse>,
      apiActionType: ApiActionType.Update | ApiActionType.Sync,
    ): Promise<void> => {
      setLoading(true);
      const successMessage =
        apiActionType == ApiActionType.Update
          ? "Repository updated"
          : "Repository successfully synced";

      try {
        const response = await action();
        const repositoryResult = response.body as IRepositoryResult;

        if (repositoryResult.success && repositoryResult.warnings.length == 0) {
          setSnackbar(successMessage, "success");
          await getRepositories();
        } else if (repositoryResult.success) {
          setSnackbar(
            `${successMessage} with warning(s):\n- ${[
              ...(repositoryResult.warnings as Array<string>),
            ].join("\n- ")}`,
            "warning",
          );
          await getRepositories();
        } else if (
          repositoryResult.workflowDefinitionsToDelete.length > 0 ||
          repositoryResult.taskDefinitionsToDelete.length > 0 ||
          repositoryResult.eventDefinitionGroupsToDelete.length > 0
        ) {
          setWorkflowDefinitionsToDelete(
            repositoryResult.workflowDefinitionsToDelete,
          );
          setTaskDefinitionsToDelete(repositoryResult.taskDefinitionsToDelete);
          setEventDefinitionGroupsToDelete(
            repositoryResult.eventDefinitionGroupsToDelete,
          );
          if (apiActionType == ApiActionType.Sync) {
            setConfirmSyncOpen(true);
          } else if (apiActionType == ApiActionType.Update) {
            setConfirmUpdateOpen(true);
          }
          return;
        } else if (
          repositoryResult.errors.length > 0 ||
          repositoryResult.conflicts.length > 0
        ) {
          setSnackbar(
            `Error(s):\n- ${[
              ...((repositoryResult.errors as Array<string>) ?? []),
              ...((repositoryResult.conflicts as Array<string>) ?? []),
            ].join("\n- ")}`,
            "error",
          );
        } else {
          setSnackbar(
            `Error: ${
              apiActionType == ApiActionType.Update ? "Update" : "Sync"
            } failed`,
            "error",
          );
        }
      } catch (e) {
        const error = e as Error;
        setSnackbar(error.message, "error");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const setSnackbar = (message: string, colour: AlertColor) => {
    setSnackbarMessage(message);
    setSnackbarColour(colour);
  };

  const handleOnSaveDetail = async (
    model: ITableRepositoryData,
    resetAddRow: () => void,
  ) => {
    if (model.id) {
      setSelectedItem(model);
      await onRowUpdate(model, false);
    } else {
      await onRowAdd(model, resetAddRow);
    }
  };

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidUrlAndBranch = (url: string, branch: string) =>
    !!url && !!branch;

  const isValidAuthentication = (
    username: string | null,
    authenticationType: AuthenticationType,
    vaultSecretKey: string | null,
  ) => {
    if (authenticationType == AuthenticationType.SSHKey && !vaultSecretKey) {
      return false;
    }
    if (
      authenticationType == AuthenticationType.Credentials &&
      (!username || !vaultSecretKey)
    ) {
      return false;
    }
    return true;
  };

  const validateUrlPrefix = (
    url: string,
    authenticationType: AuthenticationType,
  ) => {
    if (
      (authenticationType == AuthenticationType.Credentials &&
        !url.startsWith("http://") &&
        !url.startsWith("https://")) ||
      (authenticationType == AuthenticationType.SSHKey &&
        !url.startsWith("git@"))
    ) {
      return false;
    }
    return true;
  };

  const validate = (model: ITableRepositoryData) =>
    isValidUrlAndBranch(model.url, model.branch) &&
    isValidEmail(model.errorEmail) &&
    isValidAuthentication(
      model.username,
      model.authenticationType,
      model.vaultSecretKey,
    ) &&
    validateUrlPrefix(model.url, model.authenticationType);

  const renderDetail = (row: any, rowProps: any) => {
    return (
      <>
        <RepoDetail
          repo={row}
          onSave={handleOnSaveDetail}
          rowProps={rowProps}
          validate={validate}
        />
        {selectedItem && (
          <span>
            <RepositoryAlert
              apiActionType={ApiActionType.Update}
              action={onRowUpdate}
              open={confirmUpdateOpen}
              onClose={() => setConfirmUpdateOpen(false)}
              repository={selectedItem}
              taskDefinitionsToDelete={taskDefinitionsToDelete}
              workflowDefinitionsToDelete={workflowDefinitionsToDelete}
              eventDefinitionGroupsToDelete={eventDefinitionGroupsToDelete}
            />
          </span>
        )}
      </>
    );
  };

  const renderAddDetail = (
    model: ITableRepositoryData,
    resetAddRow: () => void,
    validate?: (model: any) => boolean,
  ) => {
    model.username = null;
    model.errorEmail = "";
    model.id = "";
    model.branch = "";
    model.url = "";
    model.authenticationType = AuthenticationType.Public;
    model.name = "";
    model.vaultSecretKey = null;
    return (
      <RepoDetail
        onSave={handleOnSaveDetail}
        repo={model}
        resetAddRow={resetAddRow}
        validate={validate}
      />
    );
  };

  return (
    <Paper elevation={4} className={classes.table}>
      <SnackbarAlert
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage("")}
        colour={snackbarColour}
        message={snackbarMessage}
      />
      <Loading visible={loading} />
      <DataTable
        skipPageReset={false}
        renderDetail={renderDetail}
        data={repositories ?? []}
        columns={columns}
        initialState={{ hiddenColumns: [] }}
        name="Repositories"
        aria-label="Repositories"
        total={repositories ? repositories.length : 0}
        addProps={{
          allowed: true,
          validate: validate,
          renderDetail: renderAddDetail,
          expanded: true,
        }}
        emptyDataSourceMessage={getEmptyDataSourceMessage()}
      />
    </Paper>
  );
};

export default Repositories;
