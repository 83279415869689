import React, { useEffect, useReducer, useState } from "react";
import {
  Avatar,
  Chip,
  Paper,
  Tooltip,
  Typography,
  Grid,
  Switch,
  IconButton,
  Alert,
} from "@mui/material";
import { ITableWorkflowDefinition } from "../../Common/Interfaces/ITableWorkflowDefinition";
import { useWorkflowDefinitionsService } from "../../Common/Hooks/useWorkflowDefinitionsService";
import { HttpStatus } from "../../Common/Enums/HttpStatus";
import DefinitionDetail from "./DefinitionDetail/DefinitionDetail";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ExportIcon from "@mui/icons-material/ExitToApp";
import IWorkflowDefinition from "../../Common/Interfaces/IWorkflowDefinition";
import WindowHelpers from "../../Common/Helpers/WindowHelpers";
import Relationships from "../Relationships/Relationships";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IWorkflowDefinitionVersion from "../../Common/Interfaces/IWorkflowDefinitionVersion";
import IEventDefinition from "../../Common/Interfaces/IEventDefinition";
import { useEventDefinitionService } from "../../Common/Hooks/useEventDefinitionService";
import { useEventDefinitionGroupsService } from "../../Common/Hooks/useEventDefinitionGroupsService";
import PopupVersionManager from "./Versioning/PopupVersionManager";
import { useBackgroundTask } from "../../Common/Hooks/UseBackgroundTask";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import "../../Common/Helpers/Extensions/string.extensions";
import KeyHelper from "../../Common/Helpers/KeyHelper";
import WorkflowKeyManager from "./WorkflowKeyManager/WorkflowKeyManager";
import { DataTable, Column } from "../Tables/DataTable";
import { DialogAlert } from "../DialogAlert";
import WorkflowImportModal from "./Import/WorkflowImportModal";
import { EllipseChip } from "./EllipseChip/EllipseChip";
import {
  AddWorkflowAction,
  RemoveWorkflowAction,
  WorkflowActionReducer,
} from "./WorkflowAction/WorkflowActionReducer";
import { Action } from "./WorkflowAction/Action";
import { DispatchActionType } from "./WorkflowAction/DispatchActionType";
import IEventDefinitionGroup from "../../Common/Interfaces/IEventDefinitionGroup";
import WorkflowDefinitionExport from "./Export/WorkflowDefinitionExport";
import PopupTextEdit from "../PopupTextEdit/PopupTextEdit";
import SnackbarAlert from "../SnackbarAlert";
import Loading from "../Loading/Loading";
import DefinitionDeleteAlert from "./DefinitionDeleteAlert/DefinitionDeleteAlert";
import { DefinitionType } from "../../Common/Enums/DefinitionType";
import { classes } from "../../App.Styles";

enum CancelWarningAction {
  WorkflowActionClick,
  VersionChange,
  VersionAdd,
}

interface ICancelWarning {
  open: boolean;
  action?: CancelWarningAction;
  workflowAction?: Action | undefined;
  version?: number | undefined;
}

const DefinitionsList = (): JSX.Element => {
  const service = useWorkflowDefinitionsService();
  const eventDefinitionService = useEventDefinitionService();
  const eventDefinitionGroupService = useEventDefinitionGroupsService();
  const [backgroundError, run, clearBackgroundError] = useBackgroundTask();
  const [workflowActionList, dispatch] = useReducer(WorkflowActionReducer, []);
  const [hideColumns, setHideColumns] = useState(false);
  const [error, setError] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IWorkflowDefinition>();
  const [addRowKeyManuallyChanged, setAddRowKeyManuallyChanged] =
    useState<boolean>(false);
  const [workflowDefinitionsFetchStatus, setWorkflowDefinitionsFetchStatus] =
    useState<HttpStatus>(HttpStatus.Initial);
  const [workflowDefinitions, setWorkflowDefinitions] = useState<
    ITableWorkflowDefinition[]
  >([]);
  const [eventDefinitions, setEventDefinitions] = useState<IEventDefinition[]>(
    [],
  );
  const [eventDefinitionGroups, setEventDefinitionGroups] = useState<
    IEventDefinitionGroup[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [cancelWarning, setCancelWarning] = useState<ICancelWarning>({
    open: false,
  });
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const getSelectedWorkflowVersion = (
    workflow: ITableWorkflowDefinition,
  ): number => {
    return workflow?.selectedVersion ?? workflow?.versions.length ?? 0;
  };

  const handleResize = () => {
    setHideColumns(window.innerWidth < 1200);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getWorkflowDefinitions();
    getEventDefinitions();
    getEventDefinitionGroups();

    //Cleanup
    return () => {
      setWorkflowDefinitionsFetchStatus(HttpStatus.Initial);
      setWorkflowDefinitions([]);
      setEventDefinitions([]);
      setEventDefinitionGroups([]);
      setLoading(true);
    };
  }, []);

  useEffect(() => {
    updateWindowUnload(workflowDefinitions);
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // When error is thrown by useBackgroundTask, set loading to false
  useEffect(() => {
    setLoading(false);
    setError(backgroundError?.message);
  }, [backgroundError]);

  const updateWindowUnload = (defs: ITableWorkflowDefinition[]): void => {
    const unsavedChanges = defs.find((def) => def.edited === true);
    if (unsavedChanges) {
      window.onbeforeunload = WindowHelpers.ConfirmBeforeClose;
    } else {
      window.onbeforeunload = null;
    }

    setUnsavedChanges(!!unsavedChanges);
  };

  const getWorkflowDefinitions = async (): Promise<void> => {
    setLoading(true);
    const response = await service.get();
    setWorkflowDefinitionsFetchStatus(response.status);
    const wfds = setInitialVersions(response.data);
    setWorkflowDefinitions(wfds);
    setLoading(false);
  };

  const getEventDefinitions = async (): Promise<void> => {
    setLoading(true);
    const eventDefinitions = await eventDefinitionService.$get();
    const sortedEventDefinitions = eventDefinitions?.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });

    setEventDefinitions(sortedEventDefinitions);
    setLoading(false);
  };

  const getEventDefinitionGroups = async (): Promise<void> => {
    setLoading(true);
    const eventDefinitionGroups = await eventDefinitionGroupService.$get();
    const sortedEventDefinitionGroups = eventDefinitionGroups?.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });
    setEventDefinitionGroups(sortedEventDefinitionGroups);
    setLoading(false);
  };

  const onRowAdd = async (
    item: IWorkflowDefinition,
    resetAddRow: () => void,
  ): Promise<void> => {
    run(async () => {
      setLoading(true);
      await service.$create(item);
      await getWorkflowDefinitions();
      setSuccessMessage("Workflow Definition Created");
      setLoading(false);
      setAddRowKeyManuallyChanged(false);
      resetAddRow();
    });
  };

  const onRowDelete = async (workflowDefinitionId: string): Promise<void> => {
    run(async () => {
      setLoading(true);
      await service.$delete(workflowDefinitionId);
      setWorkflowDefinitions((defs) => {
        const index = defs.findIndex((w) => w.id === workflowDefinitionId);
        if (index !== -1) {
          const updatedDefs = [...defs];
          updatedDefs.splice(index, 1);
          updateWindowUnload(updatedDefs);
          return updatedDefs;
        } else {
          return defs;
        }
      });
      setSuccessMessage("Workflow Definition Deleted");
      setLoading(false);
    });
  };

  const onDetailChange = (workflowDefinitionId: string): void => {
    setWorkflowDefinitions((defs) => {
      const index = defs.findIndex((w) => w.id === workflowDefinitionId);
      if (index !== -1) {
        defs[index].edited = true;
      }
      updateWindowUnload(defs);
      return defs;
    });
  };

  const onDefinitionVersionChange = (
    workflowDefinitionId: string,
    version: number,
  ) => {
    const definitions = [...workflowDefinitions];
    const index = definitions.findIndex((w) => w.id === workflowDefinitionId);
    if (definitions[index].edited) {
      setSelectedItem(definitions[index]);
      setCancelWarning({
        open: true,
        action: CancelWarningAction.VersionChange,
        version: version,
      });
    } else {
      HandleVersionChange(workflowDefinitionId, version);
    }
  };

  const onDetailSave = (
    workflowDefinitionId: string,
    updatedVersion: IWorkflowDefinitionVersion,
  ): void => {
    setWorkflowDefinitions((prevState) => {
      setLoading(true);
      const defs = [...prevState];
      const index = defs.findIndex((w) => w.id === workflowDefinitionId);
      const selectedVersion = getSelectedWorkflowVersion(defs[index]);

      defs[index].versions = defs[index].versions.map((version) => {
        if (version.version === selectedVersion) {
          return updatedVersion;
        }
        return version;
      });

      run(async () => {
        await service.$update(defs[index]);
        setSuccessMessage("Workflow Definition Saved");
        defs[index].edited = false;
        updateWindowUnload(defs);
      });
      setLoading(false);
      return defs;
    });
  };

  const setInitialVersions = (
    workflowDefinitions: ITableWorkflowDefinition[],
  ) => {
    return workflowDefinitions.map((def) => {
      const selectedVersion = def.versions[def.versions.length - 1];
      return { ...def, selectedVersion: selectedVersion.version ?? 0 };
    });
  };

  const onDetailCancel = (workflowDefinitionId: string): void => {
    const editWorkflowBtn = document.getElementById(
      `${workflowDefinitionId}_editWorkflowBtn`,
    );
    if (editWorkflowBtn) {
      editWorkflowBtn.click();
    }
  };

  const changeVersionEnabled = async (
    workflowDefinitionId: string,
    version: number,
  ): Promise<void> => {
    setWorkflowDefinitions((prevState) => {
      setLoading(true);
      const defs = [...prevState];
      const index = defs.findIndex((w) => w.id === workflowDefinitionId);
      const versionIndex = defs[index].versions.findIndex(
        (v) => v.version === version,
      );

      const newEnabled = !defs[index].versions[versionIndex].enabled;
      defs[index].versions[versionIndex].enabled = newEnabled;

      run(async () => {
        try {
          await service.$update(defs[index]);
        } catch (e) {
          defs[index].versions[versionIndex].enabled = !newEnabled;
          throw e;
        }
        setSuccessMessage(
          `Workflow Version ${version} ${newEnabled ? "Enabled" : "Disabled"}`,
        );
      });
      setLoading(false);
      return defs;
    });
  };

  const changeVersionDebugEnabled = async (
    workflowDefinitionId: string,
    version: number,
  ): Promise<void> => {
    setWorkflowDefinitions((prevState) => {
      setLoading(true);
      const defs = [...prevState];
      const index = defs.findIndex((w) => w.id === workflowDefinitionId);
      const versionIndex = defs[index].versions.findIndex(
        (v) => v.version === version,
      );
      const newDebug = !defs[index].versions[versionIndex].debug;
      defs[index].versions[versionIndex].debug = newDebug;

      run(async () => {
        try {
          await service.$update(defs[index]);
        } catch (e) {
          defs[index].versions[versionIndex].debug = !newDebug;
          throw e;
        }
        setSuccessMessage(
          `Workflow Debug ${newDebug ? "Enabled" : "Disabled"}`,
        );
      });
      setLoading(false);
      return defs;
    });
  };

  const onVersionAdd = async (workflowId: string): Promise<void> => {
    const definitions = [...workflowDefinitions];
    const index = definitions.findIndex((w) => w.id === workflowId);
    if (definitions[index].edited) {
      setSelectedItem(definitions[index]);
      setCancelWarning({ open: true, action: CancelWarningAction.VersionAdd });
    } else {
      HandleVersionAdd(workflowId);
    }
  };

  const getEmptyDataSourceMessage = (): string => {
    switch (workflowDefinitionsFetchStatus) {
      case HttpStatus.Success:
        return "No workflow definitions to display. Select the + button to add a new workflow definition.";
      case HttpStatus.Failure:
        return "Error fetching workflow definitions, please try again.";
      default:
        return "";
    }
  };

  const getWorkflowVersion = (
    item: ITableWorkflowDefinition,
  ): IWorkflowDefinitionVersion => {
    const selectedVersion = getSelectedWorkflowVersion(item);
    return (
      item.versions.find((v) => v.version === selectedVersion) ??
      item.versions[0]
    );
  };

  const onNameChange = (
    name: string,
    addModel: Record<string, string>,
  ): Record<string, string> => {
    if (name != undefined && !addRowKeyManuallyChanged) {
      const key = KeyHelper.GenerateKeyFromValue(name);
      const keyField = document.getElementById(
        "TableAddRow_key",
      ) as HTMLInputElement;
      keyField.value = key;

      return { name: name, key: key };
    }

    return { ...addModel, name: name };
  };

  const onKeyChange = (
    key: string,
    addModel: Record<string, string>,
  ): Record<string, string> => {
    const nameField = document.getElementById(
      "TableAddRow_name",
    ) as HTMLInputElement;
    if (nameField && nameField.value != undefined) {
      const generatedKey = KeyHelper.GenerateKeyFromValue(nameField.value);
      if (generatedKey != key) {
        setAddRowKeyManuallyChanged(true);
      }
    } else {
      setAddRowKeyManuallyChanged(true);
    }

    return { ...addModel, key: key };
  };

  const onCancelConfirm = (row: any): void => {
    const workflowId = row.original.id;
    switch (cancelWarning.action) {
      case CancelWarningAction.WorkflowActionClick:
        HandleWorkflowActionClick(row);
        return;
      case CancelWarningAction.VersionChange:
        if (cancelWarning.version == undefined) {
          throw new Error("no version set");
        }
        HandleVersionChange(workflowId, cancelWarning.version);
        return;
      case CancelWarningAction.VersionAdd:
        HandleVersionAdd(workflowId);
        return;
      default:
        throw new Error("invalid action set");
    }
  };

  const HandleVersionAdd = (workflowId: string) => {
    setLoading(true);
    run(async () => {
      const response = await service.$createNewVersion(workflowId);
      setWorkflowDefinitions((prevState) => {
        const defs = [...prevState];
        const index = defs.findIndex((w) => w.id === workflowId);
        defs[index] = {
          ...response,
          edited: false,
        } as ITableWorkflowDefinition;
        updateWindowUnload(defs);
        return defs;
      });
    });
    setLoading(false);
  };

  const HandleVersionChange = (workflowId: string, version: number) => {
    setWorkflowDefinitions((prevState) => {
      setLoading(true);
      const defs = [...prevState];
      const index = defs.findIndex((w) => w.id === workflowId);
      defs[index].selectedVersion = version;
      defs[index].edited = false;
      updateWindowUnload(defs);
      setLoading(false);
      return defs;
    });
  };

  const HandleWorkflowActionClick = (row: any) => {
    if (cancelWarning.workflowAction == undefined) {
      throw new Error("no workflowAction set");
    }

    const workflowId = row.original.id;
    setWorkflowDefinitions((defs) => {
      setLoading(true);
      const workflow = defs[defs.findIndex((w) => w.id === workflowId)];
      workflow.edited = false;
      updateWindowUnload(defs);
      setLoading(false);
      return defs;
    });

    dispatch({
      dispatchActionType: DispatchActionType.AddWorkflowAction,
      workflowAction: {
        workflowDefinitionId: workflowId,
        action: cancelWarning.workflowAction,
      },
    } as AddWorkflowAction);
    cancelWarning.workflowAction == Action.Edit &&
      closeExpandedWorkflowDefinitionRow(row, workflowId);
  };

  function closeExpandedWorkflowDefinitionRow(
    row: any,
    workflowDefinitionId: string,
  ) {
    row.toggleRowExpanded();
    dispatch({
      dispatchActionType: DispatchActionType.RemoveWorkflowAction,
      workflowDefinitionId: workflowDefinitionId,
    } as RemoveWorkflowAction);
  }

  const actionSelected = (workflowId: string, action: Action): boolean => {
    return workflowActionList.some(
      (a) => a.workflowDefinitionId === workflowId && a.action === action,
    );
  };

  const onActionClicked = (row: any, action: Action) => {
    const id = row.original.id;
    if (row.original.edited) {
      setCancelWarning({
        open: true,
        action: CancelWarningAction.WorkflowActionClick,
        workflowAction: action,
      });
    } else if (row.isExpanded && actionSelected(id, action)) {
      closeExpandedWorkflowDefinitionRow(row, id);
    } else {
      dispatch({
        dispatchActionType: DispatchActionType.AddWorkflowAction,
        workflowAction: {
          workflowDefinitionId: id,
          action: action,
        },
      } as AddWorkflowAction);
      !row.isExpanded && row.toggleRowExpanded();
    }
  };

  const updateWorkflowName = async (
    workflow: IWorkflowDefinition,
    name: string,
  ): Promise<void> => {
    run(async () => {
      setLoading(true);
      workflow.name = name;
      await service.$update(workflow);
      setSuccessMessage("Workflow Name Saved");
      setLoading(false);
    });
  };

  const Actions = ({ row }: { row: any }) => (
    <>
      <span>
        <Tooltip title="Delete">
          <span>
            <IconButton
              aria-label="Delete Workflow"
              disabled={row.original.isGitImported}
              className={classes.mediumGrey}
              onClick={() => {
                setSelectedItem(row.original);
                setDeleteOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </span>
      {selectedItem && (
        <span>
          <DefinitionDeleteAlert
            definition={selectedItem}
            open={deleteOpen}
            onClose={() => setDeleteOpen(false)}
            action={() => onRowDelete(selectedItem.id)}
            definitionType={DefinitionType.Workflow}
          />
        </span>
      )}
      <span>
        <Tooltip title="Export">
          <IconButton
            className={classes.mediumGrey}
            onClick={() => onActionClicked(row, Action.Export)}
          >
            {row.isExpanded &&
            row.original.id != "" &&
            workflowActionList.some(
              (a) =>
                a.workflowDefinitionId === row.original.id &&
                a.action === Action.Export,
            ) ? (
              <CloseIcon id="export_close" />
            ) : (
              <ExportIcon id="export_open" />
            )}
          </IconButton>
        </Tooltip>
      </span>
      <span>
        <Tooltip title="Relationships">
          <IconButton
            className={classes.mediumGrey}
            onClick={() => onActionClicked(row, Action.Relationships)}
          >
            {row.isExpanded &&
            row.original.id != "" &&
            workflowActionList.some(
              (a) =>
                a.workflowDefinitionId === row.original.id &&
                a.action === Action.Relationships,
            ) ? (
              <CloseIcon id="relationships_close" />
            ) : (
              <DeviceHubIcon id="relationships_open" />
            )}
          </IconButton>
        </Tooltip>
      </span>
      <span>
        <DialogAlert
          open={selectedItem?.id == row.original.id && cancelWarning.open}
          title={`Are you sure you want to cancel?`}
          message={undefined}
          onClose={() => setCancelWarning({ open: false })}
          action={() => onCancelConfirm(row)}
          body={
            <Alert severity="warning">
              Changes that you made to {row.original.name} will not be saved
            </Alert>
          }
          cancelText="No"
          confirmationText="Yes"
        />
        <Tooltip title="Edit Workflow">
          <span>
            <IconButton
              id={`${row.original.id}_editWorkflowBtn`}
              className={classes.mediumGrey}
              onClick={() => {
                setSelectedItem(row.original);
                onActionClicked(row, Action.Edit);
              }}
            >
              {row.isExpanded &&
              row.original.id != "" &&
              workflowActionList.some(
                (a) =>
                  a.workflowDefinitionId === row.original.id &&
                  a.action === Action.Edit,
              ) ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon id="expand_workflow" />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </span>
    </>
  );

  function getColumns() {
    const triggersCol = {
      Header: "Triggers",
      id: "triggers",
      width: 65,
      Cell: ({ row }: { row: any }) => (
        <Chip
          className={classes.darkGrey}
          label={getWorkflowVersion(row.original).tasks.reduce(
            (total, v) => total + v.triggers.length,
            0,
          )}
          variant="outlined"
        />
      ),
    } as Column<ITableWorkflowDefinition>;

    const tasksCol = {
      Header: "Tasks",
      id: "tasks",
      width: 65,
      Cell: ({ row }: { row: any }) => (
        <Chip
          className={classes.darkGrey}
          label={getWorkflowVersion(row.original).tasks.length}
          variant="outlined"
        />
      ),
    } as Column<ITableWorkflowDefinition>;

    const filtersCol = {
      Header: "Filters",
      id: "filters",
      width: 65,
      Cell: ({ row }: { row: any }) => (
        <Chip
          className={classes.darkGrey}
          label={getWorkflowVersion(row.original).filters.length}
          variant="outlined"
        />
      ),
    } as Column<ITableWorkflowDefinition>;

    const debugModeCol = {
      Header: "Debug",
      id: "debug",
      width: hideColumns ? 100 : 70,
      Cell: ({ row }: { row: any }) => {
        const workflowVersion = getWorkflowVersion(row.original);
        return (
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <Switch
                  disabled={row.original.isGitImported}
                  size="medium"
                  checked={workflowVersion.debug}
                  className={classes.debugSwitch}
                  onChange={async () =>
                    await changeVersionDebugEnabled(
                      row.original.id,
                      workflowVersion.version,
                    )
                  }
                />
              </Grid>
            </Grid>
          </Typography>
        );
      },
    } as Column<ITableWorkflowDefinition>;

    const actionsCol = {
      Header: "Actions",
      id: "actions",
      width: hideColumns ? 250 : 175,
      Cell: ({ row }: { row: any }) => <Actions row={row} />,
    } as Column<ITableWorkflowDefinition>;

    const columns = [
      {
        Header: "Name",
        id: "name",
        accessor: "name",
        width: hideColumns ? 250 : 180,
        Cell: ({ row }: any) =>
          row.original.isGitImported ? (
            <Tooltip title="Imported from Git Repository">
              <EllipseChip
                avatar={
                  <Avatar className={classes.nameEditAvatar}>
                    <AccountTreeIcon fontSize="inherit" />
                  </Avatar>
                }
                label={row.original.name}
              />
            </Tooltip>
          ) : (
            <PopupTextEdit
              name="Edit Name"
              value={row.original.name}
              onSave={(newName: any) => {
                run(
                  async () => await updateWorkflowName(row.original, newName),
                );
              }}
              required
              component={(onClick: any) => (
                <Tooltip title="Edit Name">
                  <EllipseChip
                    avatar={
                      <Avatar className={classes.nameEditAvatar}>
                        <EditIcon fontSize="inherit" />
                      </Avatar>
                    }
                    label={row.original.name}
                    variant="outlined"
                    clickable
                    onClick={onClick}
                  />
                </Tooltip>
              )}
            />
          ),
      },
      {
        Header: "Key",
        id: "key",
        accessor: "key",
        width: hideColumns ? 250 : 200,
        Cell: ({ row }: { row: any }) => (
          <WorkflowKeyManager workflow={row.original} />
        ),
      },
      {
        Header: "Version",
        id: "version",
        accessor: "versions",
        width: 150,
        Cell: ({ row }: { row: any }) => (
          <PopupVersionManager
            workflowDefinition={row.original}
            onAdd={onVersionAdd}
            setVersion={(workflowDefinitionId: string, version: number) =>
              onDefinitionVersionChange(workflowDefinitionId, version)
            }
            changeVersionEnabled={changeVersionEnabled}
            allWorkflowDefinitions={workflowDefinitions}
            readOnly={row.original.isGitImported}
          />
        ),
      },
    ] as Column<ITableWorkflowDefinition>[];

    if (!hideColumns) {
      columns.push(triggersCol);
      columns.push(tasksCol);
      columns.push(filtersCol);
    }

    columns.push(debugModeCol);
    columns.push(actionsCol);

    return columns;
  }

  const validate = (model: IWorkflowDefinition) =>
    !!model.name && KeyHelper.ValidateKey(model.key) && !!model.key;

  const handleOnAddEvent = async (model: any, resetAddRow: () => void) => {
    await onRowAdd(model, resetAddRow);
  };

  const handleCancelAddEvent = async (resetAddRow: () => void) => {
    setAddRowKeyManuallyChanged(false);
    resetAddRow();
  };

  const renderImportModal = (showImportModal: boolean, onClose: () => void) => {
    return (
      <WorkflowImportModal
        refresh={getWorkflowDefinitions}
        show={showImportModal}
        onClose={onClose}
      />
    );
  };
  const renderDetail = (rowData: any) => {
    const action = workflowActionList.filter(
      (a) => a.workflowDefinitionId === rowData.id,
    )[0]?.action;
    switch (action) {
      case Action.Relationships:
        return (
          <Relationships
            id={rowData.id}
            version={getWorkflowVersion(rowData).version}
          />
        );
      case Action.Export:
        return <WorkflowDefinitionExport definition={rowData} />;
      case Action.Edit:
        return (
          <DefinitionDetail
            workflowDefinitionId={rowData.id}
            workflowDefinitionVersion={getWorkflowVersion(rowData)}
            workflowDefinition={rowData}
            onChange={onDetailChange}
            onSave={onDetailSave}
            onCancel={onDetailCancel}
            workflowDefinitions={workflowDefinitions}
            eventDefinitions={eventDefinitions}
            eventDefinitionGroups={eventDefinitionGroups}
            edited={rowData.edited || false}
            readOnly={rowData.isGitImported}
          />
        );
    }
  };

  return (
    <React.Fragment>
      {error && (
        <SnackbarAlert
          open={true}
          onClose={() => {
            clearBackgroundError();
            setError(undefined);
          }}
          colour="error"
          message={error}
        />
      )}
      {successMessage && (
        <SnackbarAlert
          open={true}
          onClose={() => {
            setSuccessMessage(undefined);
          }}
          colour="success"
          message={successMessage}
        />
      )}
      <Loading visible={loading} />
      <Paper elevation={4} className={classes.table}>
        <DataTable
          aria-label="Workflow Definitions"
          data={workflowDefinitions ?? []}
          columns={getColumns()}
          skipPageReset={true}
          renderDetail={renderDetail}
          initialState={{ hiddenColumns: [] }}
          name="Workflow Definitions"
          total={workflowDefinitions ? workflowDefinitions.length : 0}
          addProps={{
            allowed: true,
            validate: validate,
            onSave: handleOnAddEvent,
            onCancel: handleCancelAddEvent,
            identifier: "add_workflow",
            rowFields: [
              {
                columnId: "name",
                placeholderText: "Name",
                onChange: onNameChange,
              },
              {
                columnId: "key",
                placeholderText: "Key",
                helperText: KeyHelper.KeyErrorMessage,
                onChange: onKeyChange,
              },
            ],
            disabled: unsavedChanges,
          }}
          importProps={{
            allowed: true,
            identifier: "import_workflow",
            disabled: unsavedChanges,
          }}
          renderImportModal={renderImportModal}
          emptyDataSourceMessage={getEmptyDataSourceMessage()}
          filterDisabled={unsavedChanges}
        />
      </Paper>
    </React.Fragment>
  );
};

export default DefinitionsList;
