import { useState } from "react";
import { Accordion, AccordionDetails, Grid } from "@mui/material";
import IUITaskDefinition from "../../../Common/Interfaces/IUITaskDefinition";
import ITaskDefinition from "../../../Common/Interfaces/ITaskDefinition";
import { EvaluateXPath } from "../../../Common/Helpers/PathEvaluators";
import MappableTextField from "../../MappableTextField/MappableTextField";
import Filters from "../Filters/Filters";
import ContextInput from "../../ContextInput/ContextInput";
import InitialState from "../InitialState/InitialState";
import ItemAccordion from "../DefinitionDetail/ItemAccordion";
import TaskIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { TaskType } from "../../../Common/Enums/TaskType";
import Triggers from "../Triggers/Triggers";
import TriggeredUpdates from "../Triggers/TriggeredUpdates";
import IWorkflowDefinition from "../../../Common/Interfaces/IWorkflowDefinition";
import IEventDefinition from "../../../Common/Interfaces/IEventDefinition";
import ITriggerDefinition from "../../../Common/Interfaces/ITriggerDefinition";
import ITriggeredUpdateDefinition from "../../../Common/Interfaces/ITriggeredUpdateDefinition";
import ActivityProperties from "../ActivityContext/ActivityProperties";
import { TaskTypeNameProvider } from "../../../Common/Providers/TaskTypeNameProvider";
import StandardTaskProperties from "./StandardTaskProperties";
import DueDate from "../DueDate/DueDate";
import IEventDefinitionGroup from "../../../Common/Interfaces/IEventDefinitionGroup";
import IconOutput from "../../Icons/IconOutput";
import ItemAccordionSummary from "../DefinitionDetail/ItemAccordionSummary";
import DeleteIcon from "@mui/icons-material/Delete";
import DefinitionDeleteAlert from "../DefinitionDeleteAlert/DefinitionDeleteAlert";
import { DefinitionType } from "../../../Common/Enums/DefinitionType";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  tasks: IUITaskDefinition[];
  context: string;
  onChange: (tasks: ITaskDefinition[]) => void;
  workflowDefinitions: IWorkflowDefinition[];
  eventDefinitions: IEventDefinition[];
  eventDefinitionGroups: IEventDefinitionGroup[];
  workflowDefinitionId: string;
  workflowDefinitionKey: string;
  workflowVersion: number;
}

const Tasks: React.FC<IProps> = ({
  tasks,
  onChange,
  context,
  workflowDefinitions,
  eventDefinitions,
  eventDefinitionGroups,
  workflowDefinitionId,
  workflowDefinitionKey,
  workflowVersion,
  readOnly,
}) => {
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<IUITaskDefinition>();
  const [expandedAccordions, setExpandedAccordions] = useState(
    new Array(tasks.length).fill(false),
  );
  const [accordionClicked, setAccordionClicked] = useState(false);

  const handleAccordionToggle = (index: number, clicked: boolean) => {
    setAccordionClicked(clicked);
    setExpandedAccordions((prevState) => {
      const newExpandedAccordions = [...prevState];
      newExpandedAccordions[index] = !newExpandedAccordions[index];
      return newExpandedAccordions;
    });
  };

  const onAddTask = () => {
    const task = {
      id: "",
      taskType: TaskType.Manual,
      activityContext: {
        Due: '"{{ "now" | plus_time: days: 1 }}"',
      },
      description: "",
      assigneeId: "",
      assigneeType: "",
      owner: "",
      filters: [],
      triggers: [],
      triggeredUpdates: [],
      name: "",
      emittedContext: "",
      state: {},
      retryOnFailure: undefined,
      taskKey: "",
      exclusive: false,
      tempId: `temp-id-${Math.random()}`,
    } as IUITaskDefinition;
    onChange([...tasks, task]);
    setExpandedAccordions([...expandedAccordions, false]);
  };

  const onNameChange = (index: number, value: string) => {
    tasks[index].name = value;
    onChange(tasks);
  };

  const onKeyChange = (index: number, value: string) => {
    tasks[index].taskKey = value;
    onChange(tasks);
  };

  const onTypeChange = (index: number, value: TaskType) => {
    tasks[index].taskType = value;

    if (value === TaskType.Manual) {
      tasks[index].activityContext = {};
      tasks[index].retryOnFailure = undefined;
    } else if (!tasks[index].activityContext) {
      tasks[index].activityContext = {};
    }

    if (value !== TaskType.Manual) {
      tasks[index].retryOnFailure = true;
    }

    onChange(tasks);
  };

  const onRetryChange = (index: number, value: boolean) => {
    tasks[index].retryOnFailure = value;
    onChange(tasks);
  };

  const onDescriptionChange = (index: number, value: string) => {
    tasks[index].description = value;
    onChange(tasks);
  };

  const onAssigneeIdChange = (index: number, value: string) => {
    tasks[index].assigneeId = value;
    onChange(tasks);
  };

  const onAssigneeTypeChange = (index: number, value: string) => {
    tasks[index].assigneeType = value;
    onChange(tasks);
  };

  const onOwnerChange = (index: number, value: string) => {
    tasks[index].owner = value;
    onChange(tasks);
  };

  const onFiltersChange = (index: number, filters: string[]) => {
    tasks[index].filters = filters;
    onChange(tasks);
  };

  const onEmittedContextChange = (index: number, emittedContext: string) => {
    tasks[index].emittedContext = emittedContext;
    onChange(tasks);
  };

  const onDueDateChange = (index: number, dueDate: string) => {
    tasks[index].activityContext["Due"] = dueDate;
    onChange(tasks);
  };

  const onStateChange = (index: number, state: Record<string, string>) => {
    tasks[index].state = state;
    onChange(tasks);
  };

  const onExclusiveChange = (index: number, exclusive: boolean) => {
    tasks[index].exclusive = exclusive;
    onChange(tasks);
  };

  const onActivityChange = (
    taskIndex: number,
    notification: Record<string, string>,
  ) => {
    tasks[taskIndex].activityContext = notification;
    onChange(tasks);
  };

  const onDeleteTask = async (id: string) => {
    onChange(tasks.filter((t) => t.id !== id && t.tempId !== id));
  };

  const onTriggersChange = (
    taskIndex: number,
    triggers: ITriggerDefinition[],
  ) => {
    tasks[taskIndex].triggers = triggers;
    onChange(tasks);
  };

  const onTriggeredUpdatesChange = (
    taskIndex: number,
    triggers: ITriggeredUpdateDefinition[],
  ) => {
    tasks[taskIndex].triggeredUpdates = triggers;
    onChange(tasks);
  };

  const generateTaskText = (task: IUITaskDefinition) => {
    const triggerText =
      task.triggers.length === 1
        ? "1 Trigger"
        : `${task.triggers.length} Triggers`;
    const filterText =
      task.filters.length === 1 ? "1 Filter" : `${task.filters.length} Filters`;

    return `${TaskTypeNameProvider(
      task.taskType,
    )}, ${triggerText}, ${filterText}`;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const movedTask = tasks[startIndex];
    const newTasks = [...tasks];
    newTasks.splice(startIndex, 1);
    newTasks.splice(endIndex, 0, movedTask);
    onChange(newTasks);

    const newExpandedAccordions = [...expandedAccordions];
    const valueToMove = newExpandedAccordions.splice(startIndex, 1)[0];
    newExpandedAccordions.splice(endIndex, 0, valueToMove);
    setAccordionClicked(false);
    setExpandedAccordions(newExpandedAccordions);
  };

  return (
    <>
      <ItemAccordion
        name="Tasks"
        count={tasks.length}
        icon={() => <TaskIcon />}
        onAdd={onAddTask}
        readOnly={readOnly}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {tasks.map((task, index) => {
                  const isManual = task.taskType === TaskType.Manual;
                  const isExpanded = expandedAccordions[index];

                  return (
                    <Draggable
                      key={index}
                      draggableId={`draggable-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <Accordion
                          id="task"
                          key={index}
                          expanded={isExpanded}
                          slotProps={{
                            transition: {
                              timeout: accordionClicked ? 300 : 0,
                            },
                          }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            onClick={() => handleAccordionToggle(index, true)}
                          >
                            <ItemAccordionSummary
                              key={task.id + task.name}
                              name={EvaluateXPath(task.name, context)}
                              placeholder={`Task ${index + 1}`}
                              onAction={() => {
                                setSelectedTask(task);
                                setDeleteOpen(true);
                              }}
                              actionIcon={() => (
                                <DeleteIcon
                                  data-testid={`delete-${task.taskKey}`}
                                />
                              )}
                              extraText={generateTaskText(task)}
                              readOnly={readOnly}
                              draggable
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                          <AccordionDetails>
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <StandardTaskProperties
                                task={task}
                                context={context}
                                onNameChange={(name) =>
                                  onNameChange(index, name)
                                }
                                onKeyChange={(key) => onKeyChange(index, key)}
                                onTypeChange={(type) =>
                                  onTypeChange(index, type)
                                }
                                onOwnerChange={(owner) =>
                                  onOwnerChange(index, owner)
                                }
                                onExclusiveChange={(exclusive) =>
                                  onExclusiveChange(index, exclusive)
                                }
                                readOnly={readOnly}
                              />
                              {isManual && (
                                <>
                                  <Grid
                                    item
                                    md={6}
                                    sx={{
                                      display: { xs: "none", sm: "block" },
                                    }}
                                  />
                                  <Grid item md={6} xs={12}>
                                    <MappableTextField
                                      label="Assignee ID"
                                      context={context}
                                      value={task.assigneeId}
                                      onChange={(value) =>
                                        onAssigneeIdChange(index, value)
                                      }
                                      placeholder="/xml/assignee-id/text()"
                                      helpText="An expression to specify the assignee for the task."
                                      readOnly={readOnly}
                                    />
                                  </Grid>
                                  <Grid item md={6} xs={12}>
                                    <MappableTextField
                                      label="Assignee Type"
                                      context={context}
                                      value={task.assigneeType}
                                      onChange={(value) =>
                                        onAssigneeTypeChange(index, value)
                                      }
                                      placeholder="/xml/assignee-type/text()"
                                      helpText="An expression to specify the assignee type for the task."
                                      readOnly={readOnly}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MappableTextField
                                      label="Description"
                                      context={context}
                                      value={task.description}
                                      onChange={(value) =>
                                        onDescriptionChange(index, value)
                                      }
                                      multiline
                                      placeholder="/xml/description/text()"
                                      helpText="An expression to specify the description of the task."
                                      readOnly={readOnly}
                                    />
                                  </Grid>
                                </>
                              )}
                              {!isManual && !!task.activityContext && (
                                <ActivityProperties
                                  onPropertiesChange={(activity) =>
                                    onActivityChange(index, activity)
                                  }
                                  onRetryChange={(retry) =>
                                    onRetryChange(index, retry)
                                  }
                                  context={context}
                                  task={task}
                                  readOnly={readOnly}
                                />
                              )}
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  spacing={1}
                                  className={classes.itemChild}
                                >
                                  <Grid item xs={12}>
                                    <Triggers
                                      triggers={task.triggers}
                                      onChange={(
                                        triggers: ITriggerDefinition[],
                                      ) => onTriggersChange(index, triggers)}
                                      context={context}
                                      workflowDefinitions={workflowDefinitions}
                                      eventDefinitions={eventDefinitions}
                                      eventDefinitionGroups={
                                        eventDefinitionGroups
                                      }
                                      workflowDefinitionId={
                                        workflowDefinitionId
                                      }
                                      workflowVersion={workflowVersion}
                                      parentTaskKey={task.taskKey}
                                      readOnly={readOnly}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TriggeredUpdates
                                      triggers={task.triggeredUpdates ?? []}
                                      onChange={(
                                        triggers: ITriggeredUpdateDefinition[],
                                      ) =>
                                        onTriggeredUpdatesChange(
                                          index,
                                          triggers,
                                        )
                                      }
                                      workflowDefinitions={workflowDefinitions}
                                      eventDefinitions={eventDefinitions}
                                      eventDefinitionGroups={
                                        eventDefinitionGroups
                                      }
                                      workflowDefinitionId={
                                        workflowDefinitionId
                                      }
                                      workflowVersion={workflowVersion}
                                      parentTaskKey={task.taskKey}
                                      readOnly={readOnly}
                                    />
                                  </Grid>
                                  {isManual && (
                                    <>
                                      <Grid item xs={12}>
                                        <DueDate
                                          dueDate={task.activityContext["Due"]}
                                          index={index}
                                          taskKey={task.taskKey}
                                          workflowDefinitionKey={
                                            workflowDefinitionKey
                                          }
                                          workflowDefinitionVersion={
                                            workflowVersion
                                          }
                                          readOnly={readOnly}
                                          onChange={onDueDateChange}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <InitialState
                                          onChange={(value) =>
                                            onStateChange(index, value)
                                          }
                                          state={task.state}
                                          context={context}
                                          readOnly={readOnly}
                                        />
                                      </Grid>
                                    </>
                                  )}
                                  <Grid item xs={12}>
                                    <Filters
                                      filters={task.filters}
                                      onChange={(value) =>
                                        onFiltersChange(index, value)
                                      }
                                      context={context}
                                      readOnly={readOnly}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <ContextInput
                                      onChange={(value) =>
                                        onEmittedContextChange(index, value)
                                      }
                                      value={task.emittedContext}
                                      name="Output Context"
                                      icon={() => <IconOutput />}
                                      readOnly={readOnly}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ItemAccordion>
      {selectedTask && (
        <span>
          <DefinitionDeleteAlert
            definition={selectedTask}
            open={deleteOpen}
            onClose={() => setDeleteOpen(false)}
            action={() => onDeleteTask(selectedTask.tempId ?? selectedTask.id)}
            definitionType={DefinitionType.Task}
            context={context}
          />
        </span>
      )}
    </>
  );
};

export default Tasks;
