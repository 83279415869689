import { TaskStatus } from "../Enums/TaskStatus";

const TaskStatusDisplayName: Record<TaskStatus, string> = {
  [TaskStatus.Blocked]: "Blocked",
  [TaskStatus.Cancelled]: "Cancelled",
  [TaskStatus.Completed]: "Completed",
  [TaskStatus.Failed]: "Failed",
  [TaskStatus.InProgress]: "In Progress",
  [TaskStatus.New]: "New",
};

export const TaskStatusNameProvider = (taskStatus: TaskStatus): string => {
  return TaskStatusDisplayName[taskStatus];
};
