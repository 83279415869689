import * as React from "react";
import {
  Popper,
  Fade,
  Paper,
  Typography,
  Button,
  Tooltip,
  Chip,
  Grid,
} from "@mui/material";
import useOnClickOutside from "use-onclickoutside";
import classNames from "classnames";
import PlusIcon from "@mui/icons-material/Add";
import { ITableWorkflowDefinition } from "../../../Common/Interfaces/ITableWorkflowDefinition";
import WarningIcon from "@mui/icons-material/Error";
import VersionEnabledSwitch from "./VersionEnabledSwitch";
import IWorkflowDefinitionVersion from "../../../Common/Interfaces/IWorkflowDefinitionVersion";
import ITriggeredTask from "../../../Common/Interfaces/ITriggeredTask";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  workflowDefinition: ITableWorkflowDefinition;
  onAdd: (workflowId: string) => void;
  setVersion: (workflowDefinitionId: string, version: number) => void;
  changeVersionEnabled: (workflowDefinitionId: string, version: number) => void;
  allWorkflowDefinitions: ITableWorkflowDefinition[];
}

const PopupVersionManager: React.FC<IProps> = ({
  workflowDefinition,
  onAdd,
  setVersion,
  changeVersionEnabled,
  allWorkflowDefinitions,
  readOnly,
}) => {
  const [anchor, setAnchor] = React.useState<HTMLElement>();

  const ref = React.useRef(null);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClickOutside = () => {
    setAnchor(undefined);
  };

  const onCancelClick = () => {
    setAnchor(undefined);
  };

  const currentVersion =
    workflowDefinition.selectedVersion ?? workflowDefinition.versions.length;

  const onSelectVersion = (
    event: React.MouseEvent<HTMLElement>,
    version: number,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setVersion(workflowDefinition.id, version);
  };

  const hasMultipleVersionsEnabled = (): boolean => {
    const enabledVersions = workflowDefinition.versions.filter((version) => {
      return version.enabled == true;
    });
    return enabledVersions.length > 1;
  };

  const hasNoVersionsEnabled = (): boolean => {
    return workflowDefinition.versions.every((version) => {
      return version.enabled !== true;
    });
  };

  useOnClickOutside(ref, handleClickOutside);

  const getTasksTriggeredByVersion = (
    version: IWorkflowDefinitionVersion,
  ): ITriggeredTask[] => {
    // need to check the task key of each task and see if it is contained in
    // any of the routing keys of the triggers of the other workflows

    const parentTaskKeys = version.tasks.map((task) => task.taskKey);

    return parentTaskKeys.flatMap((parentTaskKey) => {
      return allWorkflowDefinitions
        .filter((definition) => definition.id !== workflowDefinition.id)
        .flatMap((definition) => {
          return definition.versions
            .filter((version) => version.enabled === true)
            .flatMap((enabledVersion) => {
              return enabledVersion.tasks
                .filter((task) =>
                  task.triggers.some(
                    (trigger) =>
                      trigger.selectedTaskKey === parentTaskKey &&
                      trigger.selectedTriggerVersion === version.version,
                  ),
                )
                .flatMap((triggeredTask) => {
                  return {
                    taskName: triggeredTask.name,
                    workflowName: definition.name,
                  };
                });
            });
        });
    });
  };

  const createIcon = () => {
    if (hasMultipleVersionsEnabled()) {
      return (
        <WarningIcon
          className={classes.warning}
          aria-label="Warning: Multiple Versions Enabled"
        />
      );
    } else if (hasNoVersionsEnabled()) {
      return (
        <WarningIcon
          className={classes.warning}
          aria-label="Warning: No Versions Enabled"
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Manage Versions">
        <Chip
          label={`${currentVersion} of ${workflowDefinition.versions.length}`}
          variant="outlined"
          clickable
          onClick={onClick}
          icon={createIcon()}
        />
      </Tooltip>
      <Popper
        style={{ zIndex: 1000 }}
        open={anchor !== undefined}
        anchorEl={anchor}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper ref={ref} className={classes.versionPaper} elevation={6}>
              <Typography variant="h6" color="secondary">
                Versions of {workflowDefinition.name}
              </Typography>
              <Typography variant="caption" color="error">
                {hasMultipleVersionsEnabled() && (
                  <>Warning: Multiple versions are enabled</>
                )}
                {hasNoVersionsEnabled() && (
                  <>Warning: No versions are enabled</>
                )}
              </Typography>
              <Grid
                container
                spacing={1}
                className={classes.marginTop}
                style={{ maxHeight: "230px", overflow: "hidden auto" }}
              >
                {workflowDefinition.versions.map((item, index) => {
                  const s = item.tasks.length === 1 ? "" : "s";
                  const isSelectedVersion = workflowDefinition.selectedVersion
                    ? workflowDefinition.selectedVersion === item.version
                    : item.version === workflowDefinition.versions.length;
                  return (
                    <React.Fragment key={index}>
                      <Grid item xs={6} key={`version-${index}`}>
                        <Chip
                          label={`Version ${item.version} (${item.tasks.length} Task${s})`}
                          variant={isSelectedVersion ? "filled" : "outlined"}
                          color={isSelectedVersion ? "secondary" : "default"}
                          clickable={!isSelectedVersion}
                          onClick={(e) =>
                            !isSelectedVersion &&
                            onSelectVersion(e, item.version)
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <VersionEnabledSwitch
                          triggeredTasks={getTasksTriggeredByVersion(item)}
                          version={item.version}
                          confirmChangeVersionEnabled={() =>
                            changeVersionEnabled(
                              workflowDefinition.id,
                              item.version,
                            )
                          }
                          enabled={item.enabled}
                          disableControl={workflowDefinition.isGitImported}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
              <div
                className={classNames(
                  classes.buttonContainer,
                  classes.marginTop,
                )}
              >
                {!readOnly && (
                  <Button
                    onClick={onCancelClick}
                    className={classNames(classes.button, classes.buttonCancel)}
                  >
                    Cancel
                  </Button>
                )}
                {!readOnly && (
                  <Button
                    onClick={() => onAdd(workflowDefinition.id)}
                    className={classNames(classes.button, classes.buttonSave)}
                    endIcon={<PlusIcon />}
                  >
                    New Version
                  </Button>
                )}
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default PopupVersionManager;
