import React from "react";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import EmailActivityProperties from "./EmailActivityProperties";
import HttpActivityProperties from "./HttpActivityProperties";
import { TaskType } from "../../../Common/Enums/TaskType";
import AireFrameCreateFormActivityProperties from "./AireFrameCreateFormActivityProperties";
import { TaskTypeNameProvider } from "../../../Common/Providers/TaskTypeNameProvider";
import Help from "../../Help/Help";
import IUITaskDefinition from "../../../Common/Interfaces/IUITaskDefinition";
import IHeaderDefinition from "../../../Common/Interfaces/IHeaderDefinition";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  onPropertiesChange: (value: Record<string, string>) => void;
  onRetryChange: (retryOnFailure: boolean) => void;
  context: string;
  task: IUITaskDefinition;
}

const ActivityProperties: React.FC<IProps> = ({
  onPropertiesChange,
  onRetryChange,
  context,
  task,
  readOnly,
}) => {
  const taskType = task.taskType;
  const value = task.activityContext;
  return (
    <React.Fragment>
      {taskType !== undefined && (
        <Grid
          xs={12}
          item
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.subtitle}>
              {TaskTypeNameProvider(taskType)} Notification Properties
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <TextField
          select
          disabled={readOnly}
          value={task.retryOnFailure === true ? "true" : "false"}
          label="Retry on Failure"
          fullWidth
          variant="outlined"
          onChange={(e) => onRetryChange(e.target.value === "true")}
          InputProps={{
            startAdornment: (
              <Help text="Whether the task should be re-tried per system settings on failure" />
            ),
          }}
        >
          <MenuItem key={"Yes"} value={"true"}>
            Yes
          </MenuItem>
          <MenuItem key={"No"} value={"false"}>
            No
          </MenuItem>
        </TextField>
      </Grid>

      {taskType === TaskType.Email && (
        <EmailActivityProperties
          onPropertiesChange={onPropertiesChange}
          context={context}
          to={value?.To}
          subject={value?.Subject}
          body={value?.Body}
          readOnly={readOnly}
        />
      )}
      {taskType === TaskType.Http && (
        <HttpActivityProperties
          onPropertiesChange={onPropertiesChange}
          context={context}
          url={value?.Url}
          contentType={value?.ContentType}
          body={value?.Body || JSON.stringify({}, null, "\t")}
          readOnly={readOnly}
          headers={
            value?.Headers
              ? (JSON.parse(value.Headers) as IHeaderDefinition[])
              : []
          }
        />
      )}
      {taskType === TaskType.AireFrameCreateForm && (
        <AireFrameCreateFormActivityProperties
          onPropertiesChange={onPropertiesChange}
          context={context}
          correlationId={value?.CorrelationId}
          keyProp={value?.KeyProp}
          subjectId={value?.SubjectId}
          version={value?.Version}
          readOnly={readOnly}
        />
      )}
    </React.Fragment>
  );
};

export default ActivityProperties;
