import { ITableSubject } from "../Interfaces/ITableSubject";
import { ITaskInstance } from "../Interfaces/ITaskInstance";
import { ISubjectProvider } from "../Subject/ISubjectProvider";

export class TableTaskHelper {
  public static async convert(
    instances: ITaskInstance[],
    subjectProvider: ISubjectProvider,
  ): Promise<ITableSubject[]> {
    const subjectToTasks = this.groupBySubject(instances);

    const subjectPromises = Object.keys(subjectToTasks).map((subjectId) =>
      subjectProvider.getSubject(subjectId),
    );
    const subjects = await Promise.all(subjectPromises);
    return subjects.map((subject) => {
      return {
        subject: subject,
        tasks: subjectToTasks[subject.id],
      };
    });
  }

  private static groupBySubject(instances: ITaskInstance[]): {
    [subjectId: string]: ITaskInstance[];
  } {
    const subjectToTasks: { [subjectId: string]: ITaskInstance[] } = {};

    for (const task of instances) {
      subjectToTasks[task.subjectId] = [
        ...(subjectToTasks[task.subjectId] ?? []),
        task,
      ];
    }

    return subjectToTasks;
  }
}
