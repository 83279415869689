import {
  Avatar,
  Chip,
  AlertColor,
  IconButton,
  Paper,
  Tooltip,
  Alert,
} from "@mui/material";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { ITableEventDefinitionGroup } from "../../Common/Interfaces/ITableEventDefinitionGroup";
import { useEventDefinitionGroupsService } from "../../Common/Hooks/useEventDefinitionGroupsService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Cancel";
import ExportIcon from "@mui/icons-material/ExitToApp";
import EventDefinitionGroupDetail from "./EventDefinitionGroupDetail";
import { DataTable, Column } from "../Tables/DataTable";
import "../../Common/Helpers/Extensions/string.extensions";
import WindowHelpers from "../../Common/Helpers/WindowHelpers";
import { ITableEventDefinition } from "../../Common/Interfaces/ITableEventDefinition";
import { useEventDefinitionService } from "../../Common/Hooks/useEventDefinitionService";
import { HippityResponse } from "hippity";
import EventGroupImportModal from "../Definitions/Import/EventGroupImportModal";
import {
  AddEventGroupAction,
  EventGroupActionReducer,
  RemoveEventGroupAction,
} from "../Definitions/EventGroupAction/EventGroupActionReducer";
import { Action } from "../Definitions/EventGroupAction/Action";
import EventDefinitionGroupExport from "../Definitions/Export/EventDefinitionGroupExport";
import { DispatchActionType } from "../Definitions/EventGroupAction/DispatchActionType";
import { EllipseChip } from "../Definitions/EllipseChip/EllipseChip";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { HttpStatus } from "../../Common/Enums/HttpStatus";
import PopupTextEdit from "../PopupTextEdit/PopupTextEdit";
import SnackbarAlert from "../SnackbarAlert";
import Loading from "../Loading/Loading";
import { classes } from "../../App.Styles";
import DefinitionDeleteAlert from "../Definitions/DefinitionDeleteAlert/DefinitionDeleteAlert";
import { DefinitionType } from "../../Common/Enums/DefinitionType";

const EventDefinitionGroups = (): JSX.Element => {
  const groupService = useEventDefinitionGroupsService();
  const eventDefinitionService = useEventDefinitionService();
  const [groupDefinitions, setGroupDefinitions] = React.useState<
    ITableEventDefinitionGroup[]
  >([]);
  const [eventDefinitions, setEventDefinitions] = React.useState<
    ITableEventDefinition[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarColour, setSnackbarColour] =
    React.useState<AlertColor>("success");
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);

  const [selectedItem, setSelectedItem] =
    React.useState<ITableEventDefinitionGroup>();
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const [eventGroupActionList, dispatch] = useReducer(
    EventGroupActionReducer,
    [],
  );

  const [eventGroupsFetchStatus, setEventGroupsFetchStatus] =
    useState<HttpStatus>(HttpStatus.Initial);

  const getEventGroupDefinitions = useCallback(async () => {
    setLoading(true);
    let httpStatus = HttpStatus.Initial;
    try {
      const response = await groupService.$get();
      setGroupDefinitions(response);
      httpStatus = HttpStatus.Success;
    } catch (e) {
      httpStatus = HttpStatus.Failure;
    }
    setEventGroupsFetchStatus(httpStatus);
    setLoading(false);
  }, []);

  const getEmptyDataSourceMessage = (): string => {
    switch (eventGroupsFetchStatus) {
      case HttpStatus.Success:
        return "No event definition groups to display. Select the + button to add a new event definition group.";
      case HttpStatus.Failure:
        return "Error fetching event definition groups, please try again.";
      default:
        return "";
    }
  };

  const getEventDefinitions = useCallback(async () => {
    setLoading(true);
    const defs = await eventDefinitionService.$get();
    const sortedDefs = defs.sort((a, b) => a.name.localeCompare(b.name));
    setEventDefinitions(sortedDefs);
    setLoading(false);
  }, []);

  useEffect(() => {
    getEventGroupDefinitions();
    getEventDefinitions();
  }, [getEventGroupDefinitions, getEventDefinitions]);

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  useEffect(() => {
    setSkipPageReset(false);
  }, [groupDefinitions]);

  const onRowAdd = useCallback(
    async (item: ITableEventDefinitionGroup, resetAddRow: () => void) => {
      item.eventKeys = [];
      const success = await runApiCall(
        () => groupService.create(item),
        "Group Created",
      );

      if (success) {
        resetAddRow();
      }
    },
    [],
  );

  const onRowUpdate = useCallback(async (item: ITableEventDefinitionGroup) => {
    await runApiCall(() => groupService.update(item), "Group Saved");
  }, []);

  const onRowDelete = useCallback(async (id: string) => {
    await runApiCall(() => groupService.del(id as string), "Group Deleted");
  }, []);

  const onDetailCancel = (eventDefinitionGroupId: string): void => {
    const editEventGroupBtn = document.getElementById(
      `${eventDefinitionGroupId}_editEventGroupBtn`,
    );
    if (editEventGroupBtn) {
      editEventGroupBtn.click();
    }
  };

  const runApiCall = useCallback(
    async (
      action: () => Promise<HippityResponse>,
      successMessage: string,
    ): Promise<boolean> => {
      setLoading(true);
      try {
        const response = await action();
        switch (response.status) {
          case 200:
          case 204: {
            // 204 covers delete success
            setSnackbar(successMessage, "success");
            await getEventGroupDefinitions();
            setSelectedItem(undefined);
            return true;
          }
          case 400: {
            let errorText = "";
            try {
              const errors = JSON.parse(response.body as string).errors.Name;
              errorText = errors.join(" ");
            } catch (e) {
              if (typeof response?.body == "string") {
                errorText = response?.body as string;
              } else {
                errorText =
                  "Please ensure the groupKey and name values are unique";
              }
            }
            setSnackbar("The event group is invalid: " + errorText, "error");
            return false;
          }
          case 401: {
            setSnackbar(
              "Your session has expired, please log in again",
              "error",
            );
            return false;
          }
          case 403: {
            setSnackbar(
              "You don't have permission to import event groups, please contact an administrator",
              "error",
            );
            return false;
          }
          case 500: {
            setSnackbar("An Unexpected Error Occurred", "error");
            return false;
          }
          default:
            return false;
        }
      } catch (e) {
        const error = e as Error;
        setSnackbar(error.message, "error");
        return false;
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const setSnackbar = (message: string, colour: AlertColor) => {
    setSnackbarMessage(message);
    setSnackbarColour(colour);
  };

  const isNameValid = (value: string) => !!value;
  const isKeyValid = (value: string) => !!value && /^[a-z0-9_-]*$/.test(value);

  const handleOnAddEventGroup = async (model: any, resetAddRow: () => void) => {
    await onRowAdd(model, resetAddRow);
  };

  const validate = (model: any) =>
    isNameValid(model.name) && isKeyValid(model.groupKey);

  const { width } = WindowHelpers.UseWindowDimensions();
  const columns: Column<ITableEventDefinitionGroup>[] = [
    {
      Header: "Name",
      id: "name",
      accessor: "name",
      minWidth: width > 1300 ? 0 : 230,
      Cell: ({ value, row }) =>
        row.original.isGitImported ? (
          <Tooltip title="Imported from Git Repository">
            <EllipseChip
              avatar={
                <Avatar className={classes.nameEditAvatar}>
                  <AccountTreeIcon fontSize="inherit" />
                </Avatar>
              }
              label={row.original.name}
            />
          </Tooltip>
        ) : (
          <PopupTextEdit
            name="Edit Group Name"
            value={value}
            validate={(newValue: string) => isNameValid(newValue)}
            onSave={async (newName: any) => {
              const item = row.original;
              item.name = newName;
              if (item.id !== "") await onRowUpdate(item);
            }}
            required
            component={(onClick: any) => (
              <Tooltip title="Edit Event Name">
                <Chip
                  avatar={
                    <Avatar className={classes.nameEditAvatar}>
                      <EditIcon fontSize="inherit" />
                    </Avatar>
                  }
                  label={width > 900 ? value.ellipsis(45) : value.ellipsis(25)}
                  variant="outlined"
                  clickable
                  onClick={onClick}
                />
              </Tooltip>
            )}
          />
        ),
    },
    {
      Header: "Key",
      id: "groupKey",
      accessor: "groupKey",
      Cell: ({ value }) => (
        <code style={{ backgroundColor: "#d3d3d3", padding: "10px" }}>
          {value}
        </code>
      ),
    },
    {
      Header: "Events",
      id: "eventKeys",
      accessor: "eventKeys",
      Cell: ({ value }) => (
        <Chip
          className={classes.darkGrey}
          label={value?.length ?? 0}
          variant="outlined"
        />
      ),
    },
    {
      Header: "Actions",
      id: "actions",
      width: 80,
      Cell: ({ row }: { row: any }) => (
        <>
          <span>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  disabled={row.original.isGitImported}
                  className={classes.mediumGrey}
                  title="Delete"
                  onClick={() => {
                    setSelectedItem(row.original);
                    setDeleteOpen(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </span>
          {selectedItem && (
            <span>
              <DefinitionDeleteAlert
                definition={selectedItem}
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                action={() => onRowDelete(selectedItem.id)}
                definitionType={DefinitionType.EventGroup}
              />
            </span>
          )}
          <span>
            <Tooltip title="Export">
              <IconButton
                className={classes.mediumGrey}
                onClick={() => onActionClicked(row, Action.Export)}
              >
                {row.isExpanded &&
                row.original.id != "" &&
                eventGroupActionList.some(
                  (a) =>
                    a.eventDefinitionGroupId === row.original.id &&
                    a.action === Action.Export,
                ) ? (
                  <CloseIcon id="export_close" />
                ) : (
                  <ExportIcon id="export_open" />
                )}
              </IconButton>
            </Tooltip>
          </span>
          <span>
            <Tooltip title="Edit Event Group">
              <IconButton
                id={`${row.original.id}_editEventGroupBtn`}
                className={classes.mediumGrey}
                onClick={() => onActionClicked(row, Action.Edit)}
              >
                {row.isExpanded &&
                row.original.id != "" &&
                eventGroupActionList.some(
                  (a) =>
                    a.eventDefinitionGroupId === row.original.id &&
                    a.action === Action.Edit,
                ) ? (
                  <CloseIcon />
                ) : (
                  <EditIcon id="expand_event_group" />
                )}
              </IconButton>
            </Tooltip>
          </span>
        </>
      ),
    },
  ];

  const actionSelected = (eventGroupId: string, action: Action): boolean => {
    return eventGroupActionList.some(
      (a) => a.eventDefinitionGroupId === eventGroupId && a.action === action,
    );
  };

  function onActionClicked(row: any, action: Action) {
    const id = row.original.id;
    if (actionSelected(id, action)) {
      row.toggleRowExpanded();
      closeExpandedEventGroupDefinitionRow(id);
    } else {
      dispatch({
        dispatchActionType: DispatchActionType.AddEventGroupAction,
        eventGroupAction: {
          eventDefinitionGroupId: id,
          action: action,
        },
      } as AddEventGroupAction);
      !row.isExpanded && row.toggleRowExpanded();
    }
  }

  function closeExpandedEventGroupDefinitionRow(
    eventDefinitionGroupId: string,
  ) {
    dispatch({
      dispatchActionType: DispatchActionType.RemoveEventGroupAction,
      eventDefinitionGroupId: eventDefinitionGroupId,
    } as RemoveEventGroupAction);
  }

  const renderImportModal = (showImportModal: boolean, onClose: () => void) => {
    return (
      <EventGroupImportModal
        refresh={getEventGroupDefinitions}
        show={showImportModal}
        onClose={onClose}
      />
    );
  };

  const renderDetail = (row: ITableEventDefinitionGroup) => {
    const action = eventGroupActionList.filter(
      (a) => a.eventDefinitionGroupId === row.id,
    )[0]?.action;
    switch (action) {
      case Action.Export:
        return <EventDefinitionGroupExport definition={row} />;
      case Action.Edit:
        return (
          <EventDefinitionGroupDetail
            eventDefinitionGroup={row}
            eventDefinitions={eventDefinitions}
            onSave={onRowUpdate}
            onCancel={onDetailCancel}
            readOnly={row.isGitImported}
          />
        );
    }
  };

  return (
    <React.Fragment>
      <SnackbarAlert
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage("")}
        colour={snackbarColour}
        message={snackbarMessage}
      />
      <Paper elevation={4} className={classes.table}>
        <DataTable
          aria-label="Event Definition Groups"
          data={groupDefinitions ?? []}
          columns={columns}
          skipPageReset={skipPageReset}
          initialState={{ hiddenColumns: [] }}
          renderDetail={renderDetail}
          name="Event Definition Groups"
          total={groupDefinitions ? groupDefinitions.length : 0}
          addProps={{
            allowed: true,
            validate: validate,
            onSave: handleOnAddEventGroup,
            identifier: "add_event_group",
            rowFields: [
              { columnId: "name", placeholderText: "Group Name" },
              { columnId: "groupKey", placeholderText: "Group Key" },
            ],
          }}
          importProps={{
            allowed: true,
            identifier: "import_event_group",
          }}
          renderImportModal={renderImportModal}
          emptyDataSourceMessage={getEmptyDataSourceMessage()}
        />
      </Paper>
      <Loading visible={loading} />
    </React.Fragment>
  );
};

export default EventDefinitionGroups;
