import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Tab,
  Tabs,
  Select,
  MenuItem,
  Input,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";
import ItemAccordion from "../DefinitionDetail/ItemAccordion";
import MappableTextField from "../../MappableTextField/MappableTextField";
import Calendar from "@mui/icons-material/CalendarTodayOutlined";
import { useExpressionService } from "../../../Common/Hooks/useExpressionService";
import moment from "moment";
import { HttpStatus } from "../../../Common/Enums/HttpStatus";
import classNames from "classnames";
import IPreviewExpressionRequestBody from "../../../Common/Interfaces/IPreviewDueExpressionRequestBody";
import AppBar from "@mui/material/AppBar";
import Help from "../../Help/Help";
import DueDateParser from "../../../Common/Helpers/DueDateParser";
import {
  IDueDateProperties,
  IsEqual,
} from "../../../Common/Interfaces/IDueDateProperties";
import { useForm, Controller } from "react-hook-form";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

interface IProps extends IReadOnlyComponentProps {
  dueDate: string;
  index: number;
  taskKey: string;
  workflowDefinitionKey: string;
  workflowDefinitionVersion: number;
  onChange: (index: number, dueDate: string) => void;
}

const DueDate: FC<IProps> = ({
  dueDate,
  index,
  taskKey: taskKey,
  workflowDefinitionKey: workflowDefinitionKey,
  workflowDefinitionVersion: workflowDefinitionVersion,
  readOnly,
  onChange,
}) => {
  const toUIResponse = DueDateParser.ToUI(dueDate);

  const { control, setValue, watch, getValues } = useForm({
    defaultValues: {
      selectedMonths: toUIResponse.DueDateUI.Months.toString(),
      selectedWeeks: toUIResponse.DueDateUI.Weeks.toString(),
      selectedDays: toUIResponse.DueDateUI.Days.toString(),
      selectedHours: toUIResponse.DueDateUI.Hours.toString(),
      selectedMinutes: toUIResponse.DueDateUI.Minutes.toString(),
      selectedDueDateRounding: toUIResponse.DueDateUI.RoundToNext,
      selectedIsNow: toUIResponse.DueDateUI.IsNow,
      selectedDateInput: toUIResponse.DueDateUI.DateInput,
    },
  });

  const selectedMonths = watch("selectedMonths");
  const selectedWeeks = watch("selectedWeeks");
  const selectedDays = watch("selectedDays");
  const selectedHours = watch("selectedHours");
  const selectedMinutes = watch("selectedMinutes");
  const selectedDueDateRounding = watch("selectedDueDateRounding");
  const selectedIsNow = watch("selectedIsNow");
  const selectedDateInput = watch("selectedDateInput");

  const [previewMessage, setPreviewMessage] = useState<string>("");
  const [canUseUI, setCanUseUI] = useState(toUIResponse.Parsed);
  const [tabIndex, setTabIndex] = useState(canUseUI ? 0 : 1);

  const service = useExpressionService();
  const firstRender = useRef(true);

  useEffect(() => {
    // On first page render no need to update the due date.
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (tabIndex == 0) {
      updateDueDateExpressionFromUI();
    }
  }, [
    selectedMonths,
    selectedWeeks,
    selectedDays,
    selectedHours,
    selectedMinutes,
    selectedDueDateRounding,
    selectedIsNow,
    selectedDateInput,
  ]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const previewDueDate = async () => {
    const requestBody = {
      expression: dueDate,
      taskKey: taskKey,
      workflowDefinitionKey: workflowDefinitionKey,
      workflowDefinitionVersion: workflowDefinitionVersion,
    } as IPreviewExpressionRequestBody;

    const previewResponse = await service.preview(requestBody);

    if (previewResponse.status == HttpStatus.Success) {
      const preview = moment(previewResponse.data).format(
        "DD-MMM-YYYY [at] HH:mm",
      );
      setPreviewMessage(
        `If this task was created now, it would be due at ${preview}.`,
      );
    } else {
      setPreviewMessage(
        `There was an error previewing the due date expression. Please ensure the expression is correct and then retry.`,
      );
    }
  };

  const onCodeExpressionChange = (dueDateExpression: string) => {
    const toUIResponse = DueDateParser.ToUI(dueDateExpression);
    setCanUseUI(toUIResponse.Parsed);

    if (toUIResponse.Parsed) {
      // Set the values so if the user goes to the UI view it will be populated.
      setValue("selectedMonths", toUIResponse.DueDateUI.Months.toString());
      setValue("selectedWeeks", toUIResponse.DueDateUI.Weeks.toString());
      setValue("selectedDays", toUIResponse.DueDateUI.Days.toString());
      setValue("selectedHours", toUIResponse.DueDateUI.Hours.toString());
      setValue("selectedMinutes", toUIResponse.DueDateUI.Minutes.toString());
      setValue("selectedDueDateRounding", toUIResponse.DueDateUI.RoundToNext);
      setValue("selectedIsNow", toUIResponse.DueDateUI.IsNow);
      setValue("selectedDateInput", toUIResponse.DueDateUI.DateInput);
    }

    // Update the due date expression
    onChange(index, dueDateExpression);
  };

  const handleTabChange = (
    _event: ChangeEvent<unknown>,
    newTabIndex: number,
  ) => {
    setTabIndex(newTabIndex);
  };

  const updateDueDateExpressionFromUI = useCallback(() => {
    const dueDateUI = {
      Months: parseInt(getValues("selectedMonths")),
      Weeks: parseInt(getValues("selectedWeeks")),
      Days: parseInt(getValues("selectedDays")),
      Hours: parseInt(getValues("selectedHours")),
      Minutes: parseInt(getValues("selectedMinutes")),
      RoundToNext: getValues("selectedDueDateRounding"),
      DateInput: getValues("selectedDateInput"),
      IsNow: getValues("selectedIsNow"),
    } as IDueDateProperties;

    if (IsEqual(toUIResponse.DueDateUI, dueDateUI)) return;

    // Update the due date expression
    const dueDateCode = DueDateParser.ToCode(dueDateUI);
    onChange(index, dueDateCode);
  }, [getValues, onChange, index]);

  return (
    <ItemAccordion
      name={"Due Date"}
      icon={() => <Calendar />}
      readOnly={readOnly}
    >
      <div
        key={index}
        className={classes.item}
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid container spacing={2} key={index}>
          <Grid item xs={12}>
            <AppBar
              position="static"
              style={{ backgroundColor: "#fff", boxShadow: "none" }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                style={{
                  backgroundColor: "#b0bec5",
                  borderRadius: "0.5em",
                }}
                textColor="inherit"
                indicatorColor="secondary"
              >
                <Tab
                  label="UI"
                  disabled={!canUseUI}
                  style={{
                    backgroundColor: canUseUI ? "inherit" : "#9e9e9e",
                    minWidth: "160px",
                  }}
                />
                <Tab label="Code View" style={{ minWidth: "160px" }} />
              </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0}>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={2} key={0}>
                    <Grid item xs={2}>
                      <FormControlLabel
                        id="form-control-now"
                        value="now"
                        control={
                          <Controller
                            name="selectedIsNow"
                            control={control}
                            render={({ field }) => (
                              <Radio
                                checked={field.value}
                                onChange={() => {
                                  setValue("selectedIsNow", true);
                                  setValue("selectedDateInput", "");
                                }}
                                color="secondary"
                              />
                            )}
                          />
                        }
                        label="Now"
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <FormControlLabel
                            id="form-control-other"
                            value="other"
                            control={
                              <Controller
                                name="selectedIsNow"
                                control={control}
                                render={({ field }) => (
                                  <Radio
                                    checked={!field.value}
                                    onChange={() =>
                                      setValue("selectedIsNow", false)
                                    }
                                    color="secondary"
                                  />
                                )}
                              />
                            }
                            label="Custom"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Box
                            visibility={selectedIsNow ? "hidden" : "visible"}
                          >
                            <Controller
                              name="selectedDateInput"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  id="datetime-local"
                                  label="Set date"
                                  type="datetime-local"
                                  value={field.value}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e) =>
                                    field.onChange(e.target.value)
                                  }
                                />
                              )}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Controller
                        name="selectedMonths"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            id="input-months"
                            value={field.value}
                            label={"Months"}
                            fullWidth
                            variant="outlined"
                            onChange={(event) =>
                              field.onChange(event.target.value)
                            }
                            disabled={readOnly}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="selectedWeeks"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            id="input-weeks"
                            value={field.value}
                            label={"Weeks"}
                            fullWidth
                            variant="outlined"
                            onChange={(event) =>
                              field.onChange(event.target.value)
                            }
                            disabled={readOnly}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="selectedDays"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            id="input-days"
                            value={field.value}
                            label={"Days"}
                            fullWidth
                            variant="outlined"
                            onChange={(event) =>
                              field.onChange(event.target.value)
                            }
                            disabled={readOnly}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="selectedHours"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            id="input-hours"
                            value={field.value}
                            label={"Hours"}
                            fullWidth
                            variant="outlined"
                            onChange={(event) =>
                              field.onChange(event.target.value)
                            }
                            disabled={readOnly}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="selectedMinutes"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            id="input-minutes"
                            value={field.value}
                            label={"Minutes"}
                            fullWidth
                            variant="outlined"
                            onChange={(event) =>
                              field.onChange(event.target.value)
                            }
                            disabled={readOnly}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Help
                        text={
                          "Build the due date expression using the UI view or change to a code view for more fine-grained control. Making changes in the UI view will override any changes in the code view."
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} key={4}>
                    <Grid item xs={2}>
                      <Controller
                        name="selectedDueDateRounding"
                        control={control}
                        render={({ field }) => (
                          <FormControl>
                            <InputLabel id="roundto-chip-label">
                              Round to next
                            </InputLabel>
                            <Select
                              className={classes.multiSelectMenu}
                              id="select-roundto"
                              labelId="roundto-chip-label"
                              multiple
                              {...field}
                              input={<Input />}
                              MenuProps={MenuProps}
                            >
                              {[
                                "weekday",
                                "hour",
                                "month",
                                "monday",
                                "tuesday",
                                "wednesday",
                                "thursday",
                                "friday",
                                "saturday",
                                "sunday",
                              ].map((name) => (
                                <MenuItem key={name} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Grid container spacing={2} key={index}>
                <Grid item xs={12}>
                  <MappableTextField
                    context=""
                    label="Due Date"
                    id="due-date-expression"
                    onChange={(value: string) => onCodeExpressionChange(value)}
                    value={dueDate}
                    readOnly={readOnly}
                    placeholder={'{{ "now" | plus_time: days: 1 }}'}
                    helpText="An expression to specify the due date for the task."
                    multiline
                    hidePreviewSwitchAdornment={true}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <Grid item xs={12}>
              <Button
                style={{ marginLeft: 24 }}
                onClick={previewDueDate}
                className={classNames(classes.button, classes.buttonSave)}
              >
                Preview Due Date
              </Button>
              <Typography
                style={{
                  marginLeft: 24,
                  marginTop: 16,
                  display: previewMessage != "" ? "block" : "none",
                }}
              >
                {previewMessage}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </ItemAccordion>
  );
};

export default DueDate;
