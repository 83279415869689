import GlobalFilter from "./GlobalFilter";
import { styled } from "@mui/material/styles";
import { lighten } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/AddCircle";
import PublishIcon from "@mui/icons-material/Publish";
import { Button, Grid, IconButton } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import theme from "../../App/App.Theme";
import { SvgIconComponent } from "@mui/icons-material";

const PREFIX = "TableToolbar";

const classes = {
  root: `${PREFIX}-root`,
  highlight: `${PREFIX}-highlight`,
  title: `${PREFIX}-title`,
  mediumGrey: `${PREFIX}-mediumGrey`,
};

const StyledToolbar = styled(Toolbar)(() => ({
  [`&.${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.highlight}`]:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },

  [`& .${classes.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.mediumGrey}`]: {
    color: blueGrey[500],
    fill: blueGrey[500],
  },
}));

const TableToolbar = ({
  title,
  setGlobalFilter,
  globalFilter,
  addProps,
  importProps,
  customProps,
  filterDisabled,
}: {
  title: string;
  setGlobalFilter: (value?: string) => void;
  globalFilter: string;
  addProps?: {
    allowed?: boolean;
    onSelect?: () => void;
    disabled: boolean;
    identifier?: string;
  };
  importProps?: {
    allowed?: boolean;
    onSelect?: () => void;
    disabled: boolean;
    identifier?: string;
  };
  customProps?: {
    onSelect: () => void;
    icon: SvgIconComponent;
    identifier: string;
    label: string;
  };
  filterDisabled?: boolean;
}): JSX.Element => {
  return (
    <StyledToolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {title}
      </Typography>

      {customProps && (
        <Grid container justifyContent={"flex-end"}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={customProps?.onSelect}
              id={customProps?.identifier}
            >
              <span>{customProps.label}</span>
              <customProps.icon
                sx={{ ml: 1 }}
                data-testid={"custom-props-icon"}
              />
            </Button>
          </Grid>
        </Grid>
      )}

      <GlobalFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        disabled={filterDisabled}
      />

      {importProps?.allowed && (
        <IconButton
          role="button"
          aria-label="Add to table"
          className={classes.mediumGrey}
          disabled={importProps?.disabled}
          onClick={importProps?.onSelect}
          id={importProps?.identifier}
        >
          <PublishIcon />
        </IconButton>
      )}

      {addProps?.allowed && (
        <IconButton
          role="button"
          aria-label="Add to table"
          className={classes.mediumGrey}
          disabled={addProps?.disabled}
          onClick={addProps?.onSelect}
          id={addProps?.identifier}
        >
          <AddIcon />
        </IconButton>
      )}
    </StyledToolbar>
  );
};

export default TableToolbar;
