export interface ISubject {
  id: string;
  name?: string;
}

export interface IPatient extends ISubject {
  nhsNumber?: string;
  dob?: Date;
  gender?: string;
  address?: string;
  gpName?: string;
  pasNumber?: string;
}

export function isPatient(object: ISubject): object is IPatient {
  return "nhsNumber" in object || "pasNumber" in object || "gpName" in object;
}
