import { Paper } from "@mui/material";
import classNames from "classnames";
import { ISubjectProvider } from "../../Subject/ISubjectProvider";
import { IPatient, ISubject } from "../../Interfaces/ISubject";
import TaskView from "../TaskView";
import { classes } from "../../App.Styles";

class ManagementSubjectProvider implements ISubjectProvider {
  getSubject(subjectId: string): Promise<ISubject> {
    let subject: ISubject;
    if (subjectId === "example") {
      subject = {
        id: subjectId,
        name: "Example Patient",
        nhsNumber: "000 000 0000",
        dob: new Date(1990, 8, 10),
        gender: "Male",
        address: "31 Example Road, Cove, IV2 6HG",
        gpName: "Roger Hooker",
        pasNumber: "EXAMPLE",
      } as IPatient;
    } else {
      subject = {
        id: subjectId,
      };
    }

    return new Promise<ISubject>((resolve) => resolve(subject));
  }
}

const subjectProvider = new ManagementSubjectProvider();

const RuntimeWrapper = (): JSX.Element => {
  return (
    <Paper
      elevation={4}
      className={classNames(classes.table, classes.runtimeWrapper)}
    >
      <TaskView subjectProvider={subjectProvider} />
    </Paper>
  );
};

export default RuntimeWrapper;
