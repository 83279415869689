import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Loading from "../Loading/Loading";
import SnackbarAlert from "../SnackbarAlert";
import { MinimumLogLevel } from "../../Common/Enums/MinimumLogLevel";
import { useEffect, useState } from "react";
import { useAlertsConfigService } from "../../Common/Hooks/useAlertsConfigService";
import { IAlertConfig } from "../../Common/Interfaces/IAlertConfig";
import { HttpStatus } from "../../Common/Enums/HttpStatus";
import Help from "../Help/Help";
import { useAppContext } from "../../App/App.Context";
import { HippityResponse } from "hippity";

const genericErrorMessage =
  "Error fetching alert config, please try again later.";

interface IProps {
  onClose: () => void;
}

const ConfigureLogAlertsModal: React.FC<IProps> = (props: IProps) => {
  const { onClose } = props;

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [disableTests, setDisableTests] = useState<boolean>(false);
  const [alertConfigFetchStatus, setAlertsConfigFetchStatus] =
    useState<HttpStatus>(HttpStatus.Initial);

  const [alertConfig, setAlertConfig] = useState<IAlertConfig>({
    email: "",
    minimumLogLevel: MinimumLogLevel[MinimumLogLevel.Error],
    enabled: true,
    useAireGlu: false,
  });

  const service = useAlertsConfigService();
  const appContext = useAppContext();

  async function fetchAlertsConfig() {
    setLoading(true);
    let httpStatus = HttpStatus.Initial;
    try {
      const response = await service.get();
      if (response.status === 200) {
        setAlertConfig(response.body as IAlertConfig);
        setDisableTests(false);
        httpStatus = HttpStatus.Success;
      } else if (response.status === 404) {
        setDisableTests(true);
        httpStatus = HttpStatus.Success;
      } else {
        httpStatus = HttpStatus.Failure;
        if (response.status === 403) {
          setError(
            "You don't have permission to view alert config, please contact an administrator",
          );
        } else {
          setError(genericErrorMessage);
        }
      }
    } catch (e) {
      setError(genericErrorMessage);
      httpStatus = HttpStatus.Failure;
    }
    setAlertsConfigFetchStatus(httpStatus);
    setLoading(false);
  }

  useEffect(() => {
    fetchAlertsConfig();

    // Cleanup
    return () => {
      setAlertConfig({
        email: "",
        minimumLogLevel: MinimumLogLevel[MinimumLogLevel.Error],
        enabled: true,
        useAireGlu: false,
      });
      setAlertsConfigFetchStatus(HttpStatus.Initial);
      setLoading(true);
    };
  }, []);

  const onSubmit = async () => {
    setLoading(true);

    try {
      const response = await service.update(alertConfig);
      switch (response.status) {
        case 202: {
          setDisableTests(false);
          setSuccessMessage("Alerts config updated successfully");
          break;
        }
        default:
          handleHttpError(response);
          break;
      }
    } catch (e: any) {
      console.error(e);
      setError("An Unexpected Error Occurred");
    } finally {
      setLoading(false);
    }
  };

  const onDialogClose = () => {
    setError("");
    onClose();
  };

  const onTest = async () => {
    setLoading(true);
    setDisableTests(true);

    try {
      const response = await service.test();
      switch (response.status) {
        case 202: {
          setSuccessMessage("A test alert will be sent soon");
          break;
        }
        default:
          handleHttpError(response);
          setDisableTests(false);
          break;
      }
    } catch (e: any) {
      console.error(e);
      setError("An Unexpected Error Occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleHttpError = (response: HippityResponse) => {
    switch (response.status) {
      case 400: {
        setError(
          "The configuration is invalid. Please ensure you have set a minimum log level and have entered a valid email address",
        );
        break;
      }
      case 401: {
        setError("Your session has expired, please log in again");
        break;
      }
      case 403: {
        setError(
          "You don't have permission to edit the alerts config, please contact an administrator",
        );
        break;
      }
      default:
        setError("An Unexpected Error Occurred");
        break;
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={onDialogClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <Loading visible={loading} />
        <DialogTitle>
          Configure Log Alerts
          <Help
            text={
              appContext.aireGluEnabled
                ? 'To receive workflow log alerts for new logs meeting or exceeding the minimum log level, either input your email or enable AireGlu using the "Use AireGlu" switch. You can then set the minimum log level, and toggle the "Enabled" switch to on. To disable notifications, switch "Enabled" to off. If you are using AireGlu then ensure you have an AireGlu endpoint set up matching the "AireGlu Endpoint Name" displayed.'
                : 'To receive workflow log alerts via email for new logs meeting or exceeding the minimum log level, input your email, set the minimum log level, and toggle the "Enabled" switch to on below. To disable notifications, switch "Enabled" to off.'
            }
          />
        </DialogTitle>
        <DialogContent>
          {!!error && (
            <Grid container>
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          {alertConfigFetchStatus == HttpStatus.Success && (
            <Grid container spacing={2} alignItems="center">
              {alertConfig.useAireGlu && (
                <Grid item xs={12}>
                  <Alert severity="info">
                    Please ensure you have set up an AireGlu endpoint name
                    matching the "AireGlu Endpoint Name" listed below. It must
                    be protected by Client Credentials authentication.
                  </Alert>
                </Grid>
              )}
              {!alertConfig.useAireGlu && (
                <Grid item xs={appContext.aireGluEnabled ? 8 : 12}>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    value={alertConfig.email}
                    onChange={(e) => {
                      setAlertConfig((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                      setError("");
                    }}
                    required={alertConfig.enabled}
                    margin="dense"
                  />
                </Grid>
              )}
              {alertConfig.useAireGlu && (
                <Grid item xs={appContext.aireGluEnabled ? 8 : 12}>
                  <TextField
                    fullWidth
                    label="AireGlu Endpoint Name"
                    value={appContext.aireGluLogAlertEndpointName}
                    onChange={(e) => {
                      setAlertConfig((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                      setError("");
                    }}
                    disabled
                    margin="dense"
                  />
                </Grid>
              )}
              {appContext.aireGluEnabled && (
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={alertConfig.useAireGlu}
                          onChange={() => {
                            setAlertConfig((prevState) => ({
                              ...prevState,
                              useAireGlu: !alertConfig.useAireGlu,
                            }));
                            setError("");
                          }}
                          aria-label={`alerts-use-aireglu-switch`}
                          color="secondary"
                        />
                      }
                      label="Use AireGlu"
                      labelPlacement="start"
                      style={{ marginRight: 0 }}
                    />
                  </FormGroup>
                </Grid>
              )}
              <Grid item xs={8}>
                <FormControl fullWidth required>
                  <InputLabel>Minimum Log Level</InputLabel>
                  <Select
                    value={alertConfig.minimumLogLevel}
                    label="Minimum Log Level"
                    onChange={(e) => {
                      setAlertConfig((prevState) => ({
                        ...prevState,
                        minimumLogLevel: e.target.value,
                      }));
                      setError("");
                    }}
                    aria-label="Minimum Log Level"
                  >
                    {Object.keys(MinimumLogLevel)
                      .filter((v) => isNaN(Number(v)))
                      .map((logLevel) => {
                        return (
                          <MenuItem key={logLevel} value={logLevel}>
                            {logLevel}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={alertConfig.enabled}
                        onChange={() => {
                          setAlertConfig((prevState) => ({
                            ...prevState,
                            enabled: !alertConfig.enabled,
                          }));
                        }}
                        aria-label={`alerts-enabled-switch`}
                        color="secondary"
                      />
                    }
                    label="Enabled"
                    labelPlacement="start"
                    style={{ marginRight: 0 }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!alertConfig.enabled || disableTests}
            onClick={onTest}
            color="secondary"
          >
            Send Test Alert
          </Button>
          <Button onClick={onDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={!!successMessage}
        onClose={() => setSuccessMessage("")}
        colour="success"
        message={successMessage}
      />
    </>
  );
};

export default ConfigureLogAlertsModal;
