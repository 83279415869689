import React, { useEffect, useState } from "react";
import { ITableSubject } from "../Interfaces/ITableSubject";
import TaskItem from "./TaskItem";
import { IPatient, isPatient } from "../Interfaces/ISubject";
import { Button, Grid, Paper, Typography } from "@mui/material";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import ContractIcon from "@mui/icons-material/ExpandLess";
import classNames from "classnames";
import moment from "moment";
import { classes } from "../App.Styles";
import { TaskStatus } from "../Common/Enums/TaskStatus";
import { TaskStatusNameProvider } from "../Common/Providers/TaskStatusNameProvider";

interface IProps {
  subject: ITableSubject;
  onChange: () => void;
}

const SubjectView: React.FC<IProps> = ({ subject, onChange }) => {
  const [tasksOpen, setTasksOpen] = useState(false);
  const taskButtonId = `tasks-${subject.subject.id}`;
  const [newCount, setNewCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [blockedCount, setBlockedCount] = useState(0);
  const [cancelledCount, setCancelledCount] = useState(0);

  const [initialTasksToShow, setInitialTasksToShow] = useState(10);
  const [tasksToFetch] = useState(10);

  const updateCounts = () => {
    setNewCount(
      subject.tasks.filter((t) => t.activityContext.status === TaskStatus.New)
        .length,
    );
    setInProgressCount(
      subject.tasks.filter(
        (t) => t.activityContext.status === TaskStatus.InProgress,
      ).length,
    );
    setCompletedCount(
      subject.tasks.filter(
        (t) => t.activityContext.status === TaskStatus.Completed,
      ).length,
    );
    setFailedCount(
      subject.tasks.filter(
        (t) => t.activityContext.status === TaskStatus.Failed,
      ).length,
    );
    setBlockedCount(
      subject.tasks.filter(
        (t) => t.activityContext.status === TaskStatus.Blocked,
      ).length,
    );
    setCancelledCount(
      subject.tasks.filter(
        (t) => t.activityContext.status === TaskStatus.Cancelled,
      ).length,
    );
  };

  useEffect(() => {
    updateCounts();
  }, [subject]);

  const tasks = tasksOpen && (
    <Grid style={{ maxHeight: 500, overflow: "auto" }}>
      {subject.tasks.slice(0, initialTasksToShow).map((item) => (
        <TaskItem
          key={item.id}
          task={item}
          subject={subject.subject}
          onChange={onChange}
        />
      ))}
      {subject.tasks.length > initialTasksToShow && (
        <Grid style={{ marginBottom: 16, textAlign: "center" }}>
          <Button
            className={classNames(classes.button)}
            onClick={() =>
              setInitialTasksToShow(initialTasksToShow + tasksToFetch)
            }
          >
            Load More
          </Button>
        </Grid>
      )}
    </Grid>
  );

  if (isPatient(subject.subject)) {
    const patient = subject.subject as IPatient;
    const titleClass = tasksOpen
      ? classes.subjectTitleOpen
      : classes.subjectTitleClosed;
    const dob = patient.dob
      ? `${moment(patient.dob).format("DD-MMM-YYYY")} (${moment().diff(
          patient.dob,
          "years",
        )}y)`
      : null;
    return (
      <Paper elevation={2} className={classes.subjectContainer}>
        <Grid
          container
          className={classNames(classes.subjectTitle, titleClass)}
        >
          <Grid container alignItems="center" xs={3} item>
            <Typography variant="h6">{patient.name ?? patient.id}</Typography>
          </Grid>
          <Grid container alignItems="center" xs={3} item>
            <Typography>
              NHS No <b>{patient.nhsNumber}</b>
            </Typography>
          </Grid>
          <Grid container alignItems="center" xs={3} item>
            <Typography>
              Gender <b>{patient.gender}</b>
            </Typography>
          </Grid>
          <Grid container alignItems="center" xs={3} item>
            <Typography>
              DOB <b>{dob}</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className={classNames(
            classes.subjectTitle,
            classes.subjectTitleBottom,
          )}
        >
          <Grid container alignItems="center" xs={3} item>
            <Typography>
              Address{" "}
              <span className={classes.subjectText}>{patient.address}</span>
            </Typography>
          </Grid>
          <Grid container alignItems="center" xs={3} item>
            <Typography>
              GP <span className={classes.subjectText}>{patient.gpName}</span>
            </Typography>
          </Grid>
          <Grid container alignItems="center" xs={3} item>
            <Typography>
              PAS No{" "}
              <span className={classes.subjectText}>{patient.pasNumber}</span>
            </Typography>
          </Grid>
          <Grid container xs={3} justifyContent="flex-end" item>
            <Button
              className={classNames(
                classes.subjectTitle,
                classes.subjectTitleBottom,
              )}
              onClick={() => setTasksOpen(!tasksOpen)}
              id={taskButtonId}
            >
              Tasks {tasksOpen ? <ContractIcon /> : <ExpandIcon />}
            </Button>
          </Grid>
        </Grid>
        {tasks}
      </Paper>
    );
  } else {
    const titleClass = tasksOpen
      ? classes.subjectTitleOpen
      : classes.subjectTitleBottom;
    return (
      <Paper elevation={2} className={classes.subjectContainer}>
        <Grid
          container
          className={classNames(classes.subjectTitle, titleClass)}
        >
          <Grid item xs={3}>
            <Typography variant="h6">
              {subject.subject.name ?? subject.subject.id}
            </Typography>
          </Grid>
          <Grid item container alignContent="center" xs={6}>
            <Typography variant="body1">
              <StatusLabel
                label={TaskStatusNameProvider(TaskStatus.New)}
                value={newCount}
              />
              <StatusLabel
                label={TaskStatusNameProvider(TaskStatus.InProgress)}
                value={inProgressCount}
              />
              <StatusLabel
                label={TaskStatusNameProvider(TaskStatus.Completed)}
                value={completedCount}
              />
              <StatusLabel
                label={TaskStatusNameProvider(TaskStatus.Cancelled)}
                value={cancelledCount}
              />
              <StatusLabel
                label={TaskStatusNameProvider(TaskStatus.Failed)}
                value={failedCount}
              />
              <StatusLabel
                label={TaskStatusNameProvider(TaskStatus.Blocked)}
                value={blockedCount}
              />
            </Typography>
          </Grid>
          <Grid container xs={3} justifyContent="flex-end" item>
            <Button
              className={classNames(classes.subjectTitle, titleClass)}
              onClick={() => setTasksOpen(!tasksOpen)}
              id={taskButtonId}
            >
              Tasks {tasksOpen ? <ContractIcon /> : <ExpandIcon />}
            </Button>
          </Grid>
        </Grid>
        {tasks}
      </Paper>
    );
  }
};

const StatusLabel: React.FC<{ value: number; label: string }> = ({
  value,
  label,
}) => {
  return (
    <span style={{ paddingRight: 8, paddingLeft: 8 }}>
      {label} <b>({value})</b>
    </span>
  );
};

export default SubjectView;
