import { createMongoAbility } from "@casl/ability";
import * as casl from "@casl/react";
import React, { createContext, useEffect } from "react";

import {
  AppAbility,
  AppAbilities,
} from "../../Common/Authorisation/AppAbilities";
import { defineAbilitiesFor } from "../../Common/Authorisation/UserAbilities";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "oidc-react";

interface IProps extends React.PropsWithChildren {
  permissionsClaim: string;
}

const ability = createMongoAbility<AppAbilities>([]);

export const AbilityContext = createContext<AppAbility>(ability);

export const AbilityContextProvider: React.FC<IProps> = ({
  children,
  permissionsClaim,
}) => {
  const { userData } = useAuth();
  const accessToken = userData?.access_token;
  const decoded: any = accessToken ? jwtDecode(accessToken) : null;
  useEffect(() => {
    if (userData?.access_token) {
      const { rules } = defineAbilitiesFor(decoded[permissionsClaim]);
      ability.update(rules);
    }
  }, []);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export const useAbility = (): AppAbility => casl.useAbility(AbilityContext);

export const Can = casl.createContextualCan<AppAbility>(
  AbilityContext.Consumer,
);
