import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface BeforeUnloadCheckerLinkProps extends LinkProps {
  children: React.ReactNode;
}

function BeforeUnloadCheckerLink({
  children,
  ...props
}: BeforeUnloadCheckerLinkProps): JSX.Element {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (window.onbeforeunload !== null) {
      const shouldContinue = window.confirm(
        "Changes that you made may not be saved. Are you sure you want to leave this page?",
      );

      if (!shouldContinue) {
        event.preventDefault();
      }
    }
  };

  return (
    <Link {...props} onClick={handleClick}>
      {children}
    </Link>
  );
}

export default BeforeUnloadCheckerLink;
