import React, { useState } from "react";
import { Grid, IconButton, TextField } from "@mui/material";
import MappableTextField from "../../MappableTextField/MappableTextField";
import IKeyValuePair from "../../../Common/Interfaces/IKeyValuePair";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmation from "../../Delete/DeleteConfirmation";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  state: any;
  index: number;
  onChangePair: (index: number, setter: () => IKeyValuePair) => void;
  context: string;
  onDelete: () => void;
  pair: IKeyValuePair;
  validate: (pair: IKeyValuePair) => string | null;
}

const InitialStatePair: React.FC<IProps> = ({
  pair,
  onChangePair,
  onDelete,
  context,
  index,
  validate,
  readOnly,
}) => {
  const [deleting, setDeleting] = useState(false);
  const [helperText, setHelperText] = useState<string | null>();

  return deleting ? (
    <DeleteConfirmation
      name="State"
      onCancel={() => setDeleting(false)}
      onConfirm={() => {
        onDelete();
        setDeleting(false);
      }}
    />
  ) : (
    <Grid container spacing={2} key={index}>
      <Grid item xs={5}>
        <TextField
          label="Key"
          variant="outlined"
          value={pair.key}
          error={!pair.key || helperText != null}
          onChange={(e) =>
            onChangePair(index, () => {
              const message = validate({
                key: e.target?.value,
                value: pair.value,
              } as IKeyValuePair);
              setHelperText(message);
              return { key: e.target?.value, value: pair.value };
            })
          }
          fullWidth={true}
          placeholder={"Key"}
          helperText={helperText}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <MappableTextField
          label="Value"
          context={context}
          value={pair.value}
          onChange={(value) =>
            onChangePair(index, () => {
              return { key: pair.key, value: value };
            })
          }
          placeholder={"true()"}
          helpText="The initial value of this property of state."
          readOnly={readOnly}
        />
      </Grid>
      <Grid
        item
        xs={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ paddingLeft: 0 }}
      >
        {!readOnly && (
          <IconButton
            onFocus={(e) => e.stopPropagation()}
            onClick={(e) => {
              setDeleting(true);
              e.stopPropagation();
            }}
          >
            <DeleteIcon className={classes.mediumGrey} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default InitialStatePair;
