import { CssBaseline, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AuthWrapper from "./Components/AuthWrapper/AuthWrapper";
import Routing from "./Routing";
import ErrorIcon from "@mui/icons-material/Error";
import Navigation from "./Components/Navigation/Navigation";
import {
  FetchTenantStatus,
  ITenantContext,
  TenantContextProvider,
} from "./Tenant/Tenant.Context";
import { AppContextProvider } from "./App/App.Context";
import Loading from "./Components/Loading/Loading";
import { classes } from "./App.Styles";

const App = (): JSX.Element => {
  const [fetchTenantStatus, setFetchTenantStatus] =
    useState<FetchTenantStatus>();

  const tenantContextSet = (tenantContext: ITenantContext) => {
    setFetchTenantStatus(tenantContext.fetchTenantStatus);
  };

  return (
    <AppContextProvider>
      <BrowserRouter>
        <CssBaseline />
        <AuthWrapper>
          <TenantContextProvider onTenantContextSet={tenantContextSet}>
            <Navigation />
            <div className={classes.root}>
              <main className={classes.content}>
                {fetchTenantStatus == null && <Loading visible={true} />}
                {fetchTenantStatus == FetchTenantStatus.Ok && <Routing />}
                {fetchTenantStatus == FetchTenantStatus.NotFound && (
                  <Paper
                    elevation={4}
                    className={classes.table}
                    style={{ padding: 20, display: "flex" }}
                  >
                    <ErrorIcon
                      color="secondary"
                      style={{
                        marginTop: "5px",
                        marginRight: "10px",
                        verticalAlign: "sub",
                      }}
                    />
                    <Typography
                      variant="h6"
                      aria-label="Tenant not registered"
                      id="tenant-not-registered"
                    >
                      Your tenant is not registered with AireFlow, please
                      contact your system administrator to configure your
                      AireFlow tenant.
                    </Typography>
                  </Paper>
                )}
                {fetchTenantStatus == FetchTenantStatus.Error && (
                  <Paper
                    elevation={4}
                    className={classes.table}
                    style={{ padding: 20, display: "flex" }}
                  >
                    <ErrorIcon
                      color="secondary"
                      style={{
                        marginTop: "5px",
                        marginRight: "10px",
                        verticalAlign: "sub",
                      }}
                    />
                    <Typography
                      variant="h6"
                      aria-label="Tenant fetch error"
                      id="tenant-fetch-error"
                    >
                      There was an issue getting your AireFlow tenant
                      information. Please try again later or contact your system
                      administrator to configure your AireFlow tenant.
                    </Typography>
                  </Paper>
                )}
              </main>
            </div>
          </TenantContextProvider>
        </AuthWrapper>
      </BrowserRouter>
    </AppContextProvider>
  );
};

export default App;
